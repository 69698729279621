import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../css/CallToAction.css'; 

const CallToAction = () => {
  const [inView, setInView] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/contact');
  };

  const handleScroll = () => {
    const element = document.querySelector('.CallToAction');
    if (element) {
      const rect = element.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (rect.top < windowHeight * 0.8) {
        setInView(true);
      } else {
        setInView(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll(); 

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`CallToAction text-center p-5 ${inView ? 'CallToAction-fade-in' : ''}`}>
      <h1 className='text-white text-center'>
        Let's Invovate <span style={{ color: '#ffc101' }}>Together</span>
      </h1>
      <h4 className="text-white text-center">
        Looking to enhance your online presence with a unique website that drives results? Reach out today to discuss
        your project and discover how our Web Development can support your business growth in the digital space.
      </h4>
      <button type="button" className="btn btn-warning mt-4" onClick={handleClick}>
        CONTACT WITH US
      </button>
    </div>
  );
};

export default CallToAction;
