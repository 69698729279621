import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import resorcrfull from '../img/About/RESOURCEFUL.png';
import knowlage from '../img/About/KNOWLEDGEABLE.png';
import efficient from '../img/About/EFFICIENT.png';
import whychose from '../img/About/whychose-img.png';
import Footer from './Footer';
import Team from './Team';
import OVOM from './OVOM';

import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../css/About.css';

const About = () => {
  const navigate = useNavigate();

  const fadeInSectionRefs = useRef([]);

  const Click = () => {
    navigate('/team');
  };

  const Contact = () => {
    navigate('/contact');
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    fadeInSectionRefs.current.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      if (fadeInSectionRefs.current) {
        fadeInSectionRefs.current.forEach((section) => {
          if (section) observer.unobserve(section);
        });
      }
    };
  }, []);

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div>
        <div className="ABOUTUSNAME fade-in-section d-flex justify-content-center align-items-center" ref={(el) => fadeInSectionRefs.current.push(el)}>
          <button className="btn custom-button mx-auto h-auto">
            <h1>About <span style={{ color: "#fcc101" }}> US </span></h1>
            <span className="button-span"></span>
          </button>
        </div>

        <section className="vision-mission fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
          <div className="background p-4 text-center">
            <div className="ABOUT-card ">
              <p className="ABOUT-card-text" id="card-text">
                The success of Bizkranti IT Innovation is a direct reflection of the magnificent team behind it! Their passion and enthusiasm have been the greatest driving factors and are responsible for every milestone of the brand. Here’s to every individual who has been part of this wonderful journey!
              </p>
              {/* <button className="ABOUT-card-button" onClick={Click}>Meet Our Team</button> */}
              <button className="ABOUT-card-button" onClick={Contact}>CONTACT WITH US</button>
            </div>
          </div>
        </section>

        <div className="container01 container fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
          <div className="WCHU-row row">
            <div className="col-md-12 text-center">
              <h3 className="animate-charcter">WHY CHOOSE US?</h3>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row expert1 fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
            <div className="col-lg-6 col-md-6 col-sm-12 expert">
              <ul>
                <li>Expert Designers: Our team consists of experienced designers who are passionate about creating visually stunning and effective designs.</li>
                <li>Tailored Solutions: We understand that each business is unique. Our services are customized to meet your specific needs and objectives.</li>
                <li>Consistent Quality: We maintain high standards of quality in every project, ensuring that your brand’s visual elements are consistent and professional.</li>
                <li>Strategic Approach: We combine creativity with strategic thinking to ensure that your designs not only look great but also serve your business goals.</li>
                <li>Ready to elevate your brand with exceptional design? Contact us today to discuss your project and discover how we can help you create a powerful and memorable brand identity.</li>
              </ul>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 whychose-photo">
              <img src={whychose} className="img-fluid photo1" alt="Why Choose Us" />
            </div>
          </div>
        </div>

        <OVOM />

        <h1
          className="text-center fade-in-section"
          style={{
            paddingLeft: '5%',
            marginTop: '50px',
            color: '#14234c',
          }}
          ref={(el) => fadeInSectionRefs.current.push(el)}
        >
          Here are Just <span style={{ color: '#fcc101' }}> Few Reasons</span>
        </h1>

        <div className="HEREREason fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
          <div className="HEAR-cardd">
            <img src={resorcrfull} alt="Two staff members looking over documents in an office, reflecting the need for a business to establish a professional website." className="HEAR-card-image" />
            <h1 className="HEAR-card-title">RESOURCEFUL</h1>
            <p className="HEAR-card-paragraph">
              We excel at finding creative and effective solutions to meet your needs. Our team leverages a wealth of tools and strategies to overcome challenges,
              ensuring we can adapt quickly and provide innovative approaches that drive results.
            </p>
          </div>
          <div className="HEAR-cardd">
            <img src={knowlage} alt="A candle flickers beside an open book and a pen, providing a warm light in the darkness for reading and writing." className="HEAR-card-image" />
            <h1 className="HEAR-card-title">KNOWLEDGEABLE</h1>
            <p className="HEAR-card-paragraph">
              With years of experience and deep industry insights, our team stays at the forefront of the latest trends and technologies.
              We bring a wealth of expertise to every project, offering informed advice and strategies that keep you ahead of the competition.
            </p>
          </div>
          <div className="HEAR-cardd">
            <img src={efficient} alt="Three contemporary hanging lamps displayed on a wall, contributing to the overall design and lighting of the area." className="HEAR-card-image" />
            <h1 className="HEAR-card-title">EFFICIENT</h1>
            <p className="HEAR-card-paragraph">
              We prioritize efficiency in everything we do, ensuring that projects are completed on time and within budget.
              Our streamlined processes and focus on productivity allow us to deliver high-quality outcomes while minimizing waste and maximizing value.
            </p>
          </div>
          <Team />
          <div className="container-fluid ABOUT-CallToAction text-center p-5 fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
            <h1 className="text-white text-center">
              Get Expert Help for <span style={{ color: '#ffc101' }}>Your Growing Bussiness</span>
            </h1>
            <h4 className="text-white text-center">
              Looking to hire an expert offshore team to meet your development requirements?
            </h4>
            <button type="button" className="btn btn-warning mt-4" onClick={Contact}>
              Talk to an Expert
            </button>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default About;
