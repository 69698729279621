import React, { useState, useEffect, useRef } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';

// Importing images for the services section.
import canava from '../img/servicesIMG/DesignNBranding/canva.png';
import coralDraw from '../img/servicesIMG/DesignNBranding/CoralDraw.png';
import figma from '../img/servicesIMG/DesignNBranding/figma.png';
import illustrator from '../img/servicesIMG/DesignNBranding/illustrator.png';
import photoshop from '../img/servicesIMG/DesignNBranding/PhotoShop.png';
import design from '../img/servicesIMG/DesignNBranding/DESIGN.png';
import brandIdentity from '../img/servicesIMG/DesignNBranding/BrandIdentityCreation.png';
import graphicDesign from '../img/servicesIMG/DesignNBranding/GraphicDesign.png';
import packaging from '../img/servicesIMG/DesignNBranding/packaging.png';
import marketingCollaterals from '../img/servicesIMG/DesignNBranding/MarketingCollaterals.png';
import uiUxLogo from '../img/servicesIMG/DesignNBranding/UiUxLogo.png';
import rebranding from '../img/servicesIMG/DesignNBranding/rebranding.png';

// Importing other components.
import Footer from './Footer';

// Importing Bootstrap and custom CSS.
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../css/DesignBranding.css';

// The main component for Design & Branding.
const DesignBranding = () => {
    const navigate = useNavigate(); // Hook for navigating between routes.

    // Function to navigate to Contact page when called.
    const Click = () => {
        navigate('/contact');
    };

    // State to manage the selected service.
    const [selectedService, setSelectedService] = useState(null);

    // State to manage which FAQ is active (open).
    const [activeFAQ, setActiveFAQ] = useState(null);

    // State to track fade-in sections using refs for IntersectionObserver.
    const fadeInSectionRefs = useRef([]); // Storing DOM refs.

    // Function to show the details of a clicked service.
    const showDetails = (service) => {
        setSelectedService(service);
    };

    // Function to hide the details section when the 'Close' button is clicked.
    const hideDetails = () => {
        setSelectedService(null);
    };

    // Function to toggle the display of FAQs.
    const toggleFAQ = (index) => {
        setActiveFAQ(activeFAQ === index ? null : index); // Toggling between open and closed.
    };

    // useEffect hook to apply fade-in animations when elements come into view.
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('visible'); // Add class to fade in when visible.
                        observer.unobserve(entry.target); // Stop observing once animation is applied.
                    }
                });
            },
            { threshold: 0.1 } // Trigger animation when 10% of the element is visible.
        );
        fadeInSectionRefs.current.forEach((section) => {
            if (section) observer.observe(section); // Observe each section.
        });

        return () => {
            fadeInSectionRefs.current.forEach((section) => {
                if (section) observer.unobserve(section); // Clean up observer on unmount.
            });
        };
    }, []);

    // useEffect to implement a counter animation for elements with a specific class.
    useEffect(() => {
        const animateCounter = () => {
            const counters = document.querySelectorAll('.counter-value'); // Selecting all counter elements.
            const speed = 5000; // The speed of the counter animation.
            counters.forEach(counter => {
                const updateCount = () => {
                    const target = +counter.getAttribute('data-target'); // Target value from the data attribute.
                    const count = +counter.innerText; // Current count value.
                    const inc = target / speed; // Increment value.

                    if (count < target) {
                        counter.innerText = Math.ceil(count + inc); // Increment counter.
                        setTimeout(updateCount, 1); // Repeat until target is reached.
                    } else {
                        counter.innerText = target; // Ensure the target value is displayed.
                    }
                };
                updateCount();
            });
        };
        animateCounter(); // Call the counter animation function on load.
    }, []);

    // Scroll to top when the page loads.
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <>
            <div>
                {/* Title section with fade-in effect */}
                <section className="DB-title-section text-center py-5 fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
                    <div className="DB-container container">
                        <h1>Design & Branding <span style={{ color: '#fcc101' }}>Services</span></h1>
                        <p className="text-center" style={{ color: 'black' }}>
                            Our Design and Branding Services are crafted to help you create a distinctive brand image that resonates with your target audience and elevates your business.
                        </p>
                    </div>
                </section>

                {/* Technologies section with fade-in effect */}
                <section className="DB-TechnologiesWeWork text-center py-5 fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
                    <h1 className="fs-1" style={{ color: 'black' }}>
                        Technologies <span style={{ color: '#fcc101' }}>We Work</span>
                    </h1>
                    <div className="Tecch">
                        <p className="fs-4">We are Creating Projects with all these Technologies.</p>
                    </div>
                    <div className="DB-techno">
                        {/* Image rendering for the first set of technologies */}
                        <img src={canava} alt="Canva logo, emblematic of the technology we leverage for effective design and visual communication." />
                        <img src={coralDraw} alt="A lively hot air balloon logo in a spectrum of colors, expertly designed using Coral Draw technologies, evoking a sense of wonder." />
                        <img src={figma} alt="Figma" />
                    </div>
                    <div className="DB-techno">
                        {/* Image rendering for the second set of technologies */}
                        <img src={illustrator} alt="Adobe Illustrator CS6 displayed on a Mac, illustrating the software's tools for professional graphic design." />
                        <img src={photoshop} alt="Adobe Photoshop logo, illustrating the brand's commitment to excellence in digital image editing technologies." />
                    </div>
                </section>

                {/* Services section with fade-in effect */}
                <section className="DB-services fade-in-section" id="services" ref={(el) => fadeInSectionRefs.current.push(el)}>
                    <div className="DB-container-fluid d-flex align-items-center justify-content-between py-5">
                        <div className="DB-services-content">
                            <div className="DB-services-image">
                                <img src={design} alt="Contemporary logo design illustrating effective branding strategies, focusing on clarity and visual impact in identity creation." />
                            </div>
                            <div className="DB-service-boxes">
                                <div className="DB-service-box zoom-in" onClick={() => showDetails('Brand Identity Creation')}>
                                    <div className="DB-services-image1">
                                        <img src={brandIdentity} alt="Minimalist design featuring a white clock against a black background, representing the concept of time and identity." />
                                    </div>
                                    <h3>Brand Identity Creation</h3>
                                    <p>Crafting beautiful and responsive websites.</p>
                                </div>
                                <div className="DB-service-box zoom-in" onClick={() => showDetails('Graphic Design')}>
                                    <div className="DB-Graphic">
                                        <img src={graphicDesign} alt="A modern graphic design displaying a white clock prominently on a solid black background." />
                                    </div>
                                    <h3>Graphic Design</h3>
                                    <p>Building robust online stores.</p>
                                </div>
                                <div className="DB-service-box zoom-in" onClick={() => showDetails('Packaging Design')}>
                                    <div className="DB-packaging">
                                        <img src={packaging} alt="A white clock displayed prominently on a black background, emphasizing its sleek design and modern aesthetic for packaging." />
                                    </div>
                                    <h3>Packaging Design</h3>
                                    <p>Boost your website's visibility on search engines.</p>
                                </div>
                                <div className="DB-service-box zoom-in" onClick={() => showDetails('Marketing Collaterals')}>
                                    <div className="DB-Marketing">
                                        <img src={marketingCollaterals} alt="A minimalist design featuring a white clock against a black backdrop, representing the importance of time in marketing." />
                                    </div>
                                    <h3>Marketing Collaterals</h3>
                                    <p>Building robust online stores.</p>
                                </div>
                                <div className="DB-service-box zoom-in" onClick={() => showDetails('Web and Mobile UI/UX Design')}>
                                    <div className="DB-Web">
                                        <img src={uiUxLogo} alt="A striking white clock on a solid black background, showcasing a contemporary aesthetic and clear visibility of time.A striking white clock on a solid black background, showcasing a contemporary aesthetic and clear visibility of time." />
                                    </div>
                                    <h3>Web and Mobile UI/UX Design</h3>
                                    <p>Building robust online stores.</p>
                                </div>
                                <div className="DB-service-box zoom-in" onClick={() => showDetails('Rebranding')}>
                                    <div className="DB-rebranding">
                                        <img src={rebranding} alt="A white clock on a black background, symbolizing time and precision in a rebranding context." />
                                    </div>
                                    <h3>Rebranding</h3>
                                    <p>Building robust online stores.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {selectedService && (
                    <section className="DB-details" id="details">
                        <div className="DB-details-content show">
                            <h2 id="details-title">{selectedService}</h2>
                            <p id="details-description">
                                {selectedService === 'Brand Identity Creation' && 'We develop unique and cohesive brand identities that reflect your company’s values and vision. This includes logo design, color schemes, typography, and overall visual style.'}
                                {selectedService === 'Graphic Design' && 'Our team of skilled designers creates visually appealing graphics for a variety of needs, including marketing materials, social media content, and promotional items. From brochures to banners, we ensure your visuals captivate and engage. We make secure, versatile, and user-friendly online stores that offer consistent shopping experiences.'}
                                {selectedService === 'Packaging Design' && 'We create innovative and attractive packaging designs that not only protect your products but also enhance their appeal on the shelves. Our designs are tailored to reflect your brand and attract your target market.'}
                                {selectedService === 'Marketing Collaterals' && 'We design effective marketing materials such as business cards, flyers, posters, and digital assets. These collaterals are designed to align with your brand’s identity and communicate your message effectively.'}
                                {selectedService === 'Web and Mobile UI/UX Design' && 'A seamless and engaging user experience is essential for any digital platform. We design intuitive and user-friendly interfaces for websites and mobile apps, ensuring a smooth and enjoyable user journey.'}
                                {selectedService === 'Rebranding' && 'If your brand needs a fresh start, our rebranding services will help you redefine your brand image. We analyze your current brand, understand your goals, and create a revitalized identity that aligns with your new vision.'}
                            </p>
                            {/* <p id="additional-info">Additional information about {selectedService}...</p> */}
                            <button className="bt1" onClick={hideDetails}>Close</button>
                            <button className="bt2" onClick={Click}>Enquire Us</button>
                        </div>
                    </section>
                )}
                <section className="DB-FAQ fade-in-section" id="FAQ" ref={(el) => fadeInSectionRefs.current.push(el)}>
                    <div className="DB-faq-footer">
                        <h2 className="DB-faq-header">Frequently <span style={{ color: '#fcc101' }}>Asked Questions</span></h2>
                        {[
                            { question: 'Do I really need branding?', answer: 'If you want your business to be successful, yes. Branding is an important part of your marketing strategy. By distilling your business into memorable elements tailored to your target audience, you can build brand awareness, develop trust and attract more customers.' },
                            { question: 'How often should I update my branding?', answer: 'Theres no set rule but its always worth assessing your branding every few years to make sure it still aligns with your offering. Businesses are constantly evolving over time; it’s only natural that your branding should change to reflect this. From a minor refresh to a complete overhaul, updating your branding can be a great boost.' },
                            { question: 'Can you create my brand identity from scratch?', answer: 'Absolutely! Creating brands from the ground up is one of our specialties. As well as working with brand-new startups, we also help established businesses to realign their branding.' },
                            { question: 'Do you offer rebranding services?', answer: 'We do! If you have identified the need for a bit of a change, Red Kites brand experts can help you to implement it. Whether you have your own ideas of what you are looking for, or you are happy to leave it up to us, well work with you to come up with new ideas that better represent your brand.' },
                            { question: 'Why is graphic design important for my business?', answer: 'Great graphic design helps your business succeed by having an impact on their audience and creating a certain feeling or impression. From existing customers and prospective buyers to competitors and internal stakeholders, everyone who interacts with your business will make decisions based on the visual impact of your marketing collateral, in-store signage, promotional materials and printed media. Carefully considered graphic design helps your business to embody certain traits, engage with an audience and cultivate brand awareness, all of which can help to boost your profits when done right.' }
                        ].map((faq, index) => (
                            <div className="DB-faq-item" key={index}>
                                <button className="DB-faq-question" onClick={() => toggleFAQ(index)}>
                                    <span className="DB-faq-symbol-circle"><span className="faq-symbol">{activeFAQ === index ? '-' : '+'}</span></span>
                                    {faq.question}
                                </button>
                                {activeFAQ === index && (
                                    <div className="DB-faq-answer">
                                        <p>{faq.answer}</p>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </section>
                <section>
                    <div className="container-fluid DB-CallToAction text-center p-5 fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
                        <h1 className='text-white text-center'>
                            Let’s  <span style={{ color: '#ffc101' }}>Begin</span>
                        </h1>
                        <h4 className="text-white text-center">
                            Stand Out in the Crowd ,Start Building a Memorable Brand Today
                            Get a Quote Today
                        </h4>
                        <button type="button" className="btn btn-warning mt-4" onClick={() => navigate('/contact')}>
                            CONTACT WITH US
                        </button>
                    </div>
                </section>

                {/* Footer component */}
                <Footer />
            </div>
        </>
    );
};

export default DesignBranding;
