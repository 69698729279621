import React, { useState, useEffect } from 'react';
import '../css/PopUp.css';

const PopUp = () => {
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [emailError, setEmailError] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [showThankYou, setShowThankYou] = useState(false);

    // Effect to handle automatic closing of the thank-you message
    useEffect(() => {
        if (showThankYou) {
            const timer = setTimeout(() => {
                setShowThankYou(false);
            }, 2000); // Hide after 2 seconds

            return () => clearTimeout(timer); // Cleanup on unmount or when showThankYou changes
        }
    }, [showThankYou]);

    // Effect to display popup after 10 seconds if form is not submitted
    useEffect(() => {
        const formSubmitted = localStorage.getItem('formSubmitted');

        if (!formSubmitted) {
            const timer = setTimeout(() => {
                setIsVisible(true);
            }, 10000); // Show popup after 10 seconds

            return () => clearTimeout(timer); // Cleanup on unmount
        }
    }, []);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const emailRegex = /@/; // Only check for the presence of '@'
        let valid = true;

        // Email validation
        if (!emailRegex.test(email)) {
            setEmailError('Please enter a valid email address.');
            valid = false;
        } else {
            setEmailError('');
        }

        // If the form is valid
        if (valid) {
            const formData = {
                fullName: document.getElementById('first-name').value,
                email: email,
                phone: phone || null, // Allow phone to be optional
                yourCompanyName: document.getElementById('location').value || null, // Company name optional
                selectedOption: document.getElementById('options').value,
                message: document.getElementById('message').value || null, // Message optional
            };

            try {
                const response = await fetch('https://backend.bizkranti.com/popup/save', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });

                if (response.ok) {
                    await response.json();
                    setShowThankYou(true);
                    setIsVisible(false); // Hide popup
                    localStorage.setItem('formSubmitted', 'true'); // Mark form as submitted
                } else {
                    const errorData = await response.json();
                    alert(`Failed to submit the form: ${errorData.msg || 'Please try again.'}`);
                }
            } catch (error) {
                alert('An error occurred while submitting the form. Please try again.');
            }
        }
    };

    const handleClose = () => {
        setIsVisible(false); // Close the popup
        setShowThankYou(false); // Close thank-you message
    };

    return (
        <>
            {isVisible && !showThankYou && (
                <section className="Pop-ApplyForm">
                    <div className="Pop-form-container container">
                        <button className="close-btn" onClick={handleClose}>
                            &times;
                        </button>
                        <form id="authForm" onSubmit={handleSubmit}>
                            <h3 className="text-center" style={{ color: 'white', fontWeight: 'bold' }}>
                                Get a Free Consultation with <span style={{ color: '#fcc101' }}>BizKranti</span>
                            </h3>
                            <div className="Pop-form-group">
                                <label htmlFor="first-name">Full Name:</label>
                                <input type="text" id="first-name" placeholder="Enter your full name" required autoFocus />
                            </div>
                            <div className="Pop-form-group">
                                <label htmlFor="email">Email:</label>
                                <input type="email" id="email" value={email} onChange={handleEmailChange} placeholder="Enter your email" required />
                                {emailError && <span className="error-message">{emailError}</span>}
                            </div>
                            <div className="Pop-form-group">
                                <label htmlFor="phone">Mobile Number:</label>
                                <input type="text" id="phone" value={phone} onChange={handlePhoneChange} placeholder="Enter your mobile number (optional)" />
                            </div>
                            <div className="Pop-form-group">
                                <label htmlFor="location">Your Company Name:</label>
                                <input type="text" id="location" placeholder="Enter your company name" />
                            </div>
                            <div className="Pop-form-group">
                                <label htmlFor="options">Service you are interested in:</label>
                                <select id="options" required>
                                    <option value="">--Select an option--</option>
                                    <option value="Web Development">Web Development</option>
                                    <option value="App Development">App Development</option>
                                    <option value="UI/UX Development">UI/UX Development</option>
                                    <option value="SEO & Content">SEO & Content</option>
                                    <option value="Digital Marketing">Digital Marketing</option>
                                    <option value="Design Branding">Design Branding</option>
                                </select>
                            </div>
                            <div className="Pop-form-group">
                                <label htmlFor="message">Message:</label>
                                <input type="text" id="message" placeholder="Enter your message (optional)" />
                            </div>
                            <div className="Pop-form-group">
                                <button type="submit">Submit</button>
                            </div>
                        </form>
                    </div>
                </section>
            )}
            {showThankYou && (
                <section className="Pop-ThankYou">
                    <div className="Pop-thankyou-container container">
                        <button className="close-btn" onClick={handleClose}>
                            &times;
                        </button>
                        <h3 className="text-center" style={{ color: 'red', fontWeight: 'bold' }}>
                            Thank you for your submission!
                        </h3>
                        <p className="text-center" style={{ color: 'white' }}>
                            We have received your information and will get back to you soon.
                        </p>
                    </div>
                </section>
            )}
        </>
    );
};

export default PopUp;
