import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Import images
import HomeApp from '../img/servicesIMG/AppDevelopment/HomeApp.png';
import ios from '../img/servicesIMG/AppDevelopment/ios.png';
import react from '../img/servicesIMG/AppDevelopment/react.png';
import Android from '../img/servicesIMG/AppDevelopment/Android.png';
import watch from '../img/servicesIMG/AppDevelopment/watch-f.svg';
import java from '../img/technologies/java.png';
import android from '../img/technologies/android.png';
import swift from '../img/technologies/swift.png';
import flutter from '../img/technologies/flutter.png';
import kotlin from '../img/technologies/kotlin.png';

// Import components
import Footer from './Footer';

// Import styles
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../css/AppDevelopment.css';

function Appdevelopment() {
  const navigate = useNavigate();
  const location = useLocation();
  const fadeInSectionRefs = useRef([]);

  const [activeIndex, setActiveIndex] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const technologies = [
    { img: android, title: 'Android', description: 'Android (Java/Kotlin) - Used for developing native Android applications.' },
    { img: java, title: 'Java', description: 'Java - Back-End Development for large-scale applications.' },
    { img: swift, title: 'Swift', description: 'Swift - Preferred for Apple ecosystem apps due to its performance and safety.' },
    { img: flutter, title: 'Flutter', description: 'Flutter - Used for cross-platform mobile, web, and desktop applications.' },
    { img: kotlin, title: 'Kotlin', description: 'Kotlin - Used for Android app development with concise syntax and modern features.' },
  ];

  const faqItems = [
    { question: 'Can you build apps for both iOS and Android?', answer: 'Yes, most of our apps support both iOS and Android platforms.' },
    { question: 'How do you make sure you build what I want?', answer: 'We start with a meeting to understand your project requirements.' },
    { question: 'How do you keep my app and data secure?', answer: 'We implement various security measures to protect your data.' },
    { question: 'Can you deliver my project more quickly?', answer: 'Yes, contact us early so we can adjust the timeline if needed.' },
    { question: 'Can I make changes to my app after launch?', answer: 'Yes, we recommend launching an MVP first and then making updates.' },
  ];

  const appData = [
    { image: Android, alt: 'Android icon', title: 'Android App Development', description: 'Specialized Android app development.' },
    { image: ios, alt: 'Apple logo', title: 'iOS App Development', description: 'Delivering top-notch iPhone app development.' },
    { image: react, alt: 'React Native symbol', title: 'React Native Application Development', description: 'Offering React Native app development.' },
    { image: watch, alt: 'Wearable app development', title: 'Wearable Application Development', description: 'Developing next-gen wearable apps.' },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 2) % technologies.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [technologies.length]);

  const displayedItems = technologies.slice(currentIndex, currentIndex + 2);

  const toggleFAQ = (index) => setActiveIndex(activeIndex === index ? null : index);

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.replace('#', ''));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.hash]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          observer.unobserve(entry.target);
        }
      });
    }, { threshold: 0.1 });

    fadeInSectionRefs.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      fadeInSectionRefs.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  return (
    <>
      <section className="container-fluid py-0 fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
        <div className="row align-items-center AD-row">
          <div className="col-md-6 py-5 ps-5">
            <h1 className="text-dark">Mobile App <br /> <span style={{ color: '#fcc101' }}>Development</span></h1>
            <h4>Design compelling in-app experiences with cutting-edge UX designs.</h4>
            <button type="button" className="AD-button btn btn-primary" onClick={() => navigate('/contact')}>Book a Meeting</button>
          </div>
          <div className="col-md-6">
            <img src={HomeApp} alt="App development" className="img-fluid" />
          </div>
        </div>
      </section>

      <section className="AD-TechnologiesWeWork text-center py-5 fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
        <div className="AD-TechnologiesWeWork-mainheader">
          <h1>Technologies <span style={{ color: '#fcc101' }}>We Work</span></h1>
          <p>We are creating projects with these technologies.</p>
        </div>
        <div className="container-fluid" id="AppTechnologies">
          <div className="row">
            {displayedItems.map((tech, index) => (
              <div key={index} className="col-md-6 mb-4">
                <div className="AD-TechnologiesWeWork-div">
                  <img src={tech.img} alt={tech.title} className="AD-TechnologiesWeWork-img" />
                  <div className="AD-TechnologiesWeWork-content">
                    <h5>{tech.title}</h5>
                    <p>{tech.description}</p>
                    <button className="AD-TechnologiesWeWork-button btn btn-primary" onClick={() => navigate('/blog#DigitalMarketing')}>Read More</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="container containerr py-5 fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
        <div className="row">
          <h1 style={{ color: 'white' }}>
            Our iOS and Android app offers a unique experience with a <span style={{ color: '#fcc101' }}>user-friendly interface.</span>
          </h1>
          {appData.map((app, index) => (
            <div className="col-md-6 col-lg-3 mb-4" key={index}>
              <div className="APP-card">
                <img src={app.image} alt={app.alt} className="card-img-top" />
                <div className="card-body">
                  <h5 className="card-title">{app.title}</h5>
                  <p className="card-text">{app.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section id="FAQ" className="AD-FAQ fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
        <div className="AD-faq-footer">
          <h2 className="AD-faq-header">Frequently <span style={{ color: '#fcc101' }}>Asked Questions</span></h2>
          {faqItems.map((item, index) => (
            <div className="AD-faq-item" key={index}>
              <button className="AD-faq-question" onClick={() => toggleFAQ(index)} aria-expanded={activeIndex === index}>
                <span className="AD-faq-symbol-circle"><span className="faq-symbol">{activeIndex === index ? '-' : '+'}</span></span>
                {item.question}
              </button>
              <div className={`AD-faq-answer ${activeIndex === index ? 'd-block' : 'd-none'}`}>
                <p>{item.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section>
        <div className="container-fluid AD-CallToAction text-center p-5 fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
          <h1 className="text-white">Get Your App on <span style={{ color: '#ffc101' }}>the Market</span></h1>
          <h4 className="text-white">Start Building Your Dream App Now</h4>
          <button type="button" className="btn btn-warning mt-4" onClick={() => navigate('/contact')}>CONTACT WITH US</button>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Appdevelopment;
