import React from 'react';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';

const Refunds = () => {
  return (
    <div className="container mt-5">
      <h1 className="display-4 text-center mb-4">Refund and Cancellation Policy</h1>
      
      <div className="mb-4">
        <h3>Bizkranti IT Innovation Pvt Ltd.</h3>
        <p>Thank you for choosing Bizkranti IT Innovation Pvt Ltd for your website development, application, and digital marketing needs. We value your trust and want to ensure you have a clear understanding of our policies. Please read our refund and cancellation policy carefully.</p>

        <h3>Refund Policy</h3>
        <h4>1. Scope:</h4>
        <p>This policy applies to all services provided by Bizkranti IT Innovations Pvt Ltd, including website development, application development, and digital marketing services.</p>

        <h4>2. Refund Eligibility:</h4>
        <p>Refunds may be considered under the following circumstances:</p>
        <ul>
          <li>The service was not delivered as per the agreed-upon specifications in the service contract.</li>
          <li>There are significant issues affecting the functionality or performance of the delivered service.</li>
        </ul>

        <h4>3. Refund Process:</h4>
        <p>To request a refund, the client must:</p>
        <ul>
          <li>Submit a written refund request within 07 days of service delivery.</li>
          <li>Clearly state the reasons for the refund request.</li>
          <li>Bizkranti IT Innovations Pvt Ltd will review the request and respond within 07 business days.</li>
          <li>If the refund is approved, Bizkranti IT Innovations Pvt Ltd will process the refund within 30 days.</li>
        </ul>

        <h4>4. Non-Refundable Items</h4>
        <p>Certain services may be non-refundable. These will be clearly communicated during the service agreement process.</p>

        <h3>Cancellation Policy:</h3>
        <h4>1. Scope:</h4>
        <p>This policy applies to all services provided by Bizkranti IT Innovation Pvt Ltd.</p>

        <h4>2. Cancellation Process:</h4>
        <p>Clients may request the cancellation of services by providing written notice to Bizkranti IT Innovations Pvt Ltd.</p>
        <p>Cancellation requests should include the reason for cancellation and be submitted at least 30 days before the next billing cycle.</p>

        <h4>3. Refunds for Cancellations:</h4>
        <p>If a cancellation request is received:</p>
        <ul>
          <li>15 or more days before the next billing cycle: A pro-rated refund may be considered.</li>
          <li>Less than 15 days before the next billing cycle: No refund will be provided.</li>
        </ul>

        <h3>Contact Information:</h3>
        <p>If you have any questions or concerns regarding our refund and cancellation policy, please contact our customer service at <a href="mailto:support@bizkranti.com">support@bizkranti.com</a>.</p>
        <p>Bizkranti IT Innovations Pvt Ltd<br />
           Faizpur Tal Yawal Dist Jalgaon 425503<br />
           <a href="mailto:support@bizkranti.com">support@bizkranti.com</a><br />
           Contact no: 7559355593</p>
        <p className="text-muted">Note: This policy is subject to change without notice. Please check our website for the most up-to-date version.</p>
      </div>
    </div>
  );
};

export default Refunds;
