import React, { useState } from 'react';
import '../css/Form.css';
import Footer from './Footer';

const Form = () => {
  const [firstname, setfirstname] = useState('');
  const [lastname, setlastname] = useState('');
  const [email, setemail] = useState('');
  const [phone, setPhone] = useState('');
  const [location, setLocation] = useState('');
  const [file, setFile] = useState(null);
  const [emailError, setemailError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^[6-9]\d{9}$/;

    let valid = true;

    if (!emailRegex.test(email)) {
      setemailError('Please enter a valid email address.');
      valid = false;
    } else {
      setemailError('');
    }

    if (!phonePattern.test(phone)) {
      setPhoneError('Please enter a valid Indian mobile number.');
      valid = false;
    } else {
      setPhoneError('');
    }

    if (valid) {
      const formData = new FormData();
      formData.append('firstname', firstname);
      formData.append('lastname', lastname);
      formData.append('email', email);
      formData.append('phone', phone);
      formData.append('location', location);
      if (file) {
        formData.append('pdfname', file);
      }

      try {
        const response = await fetch('https://backend.bizkranti.com/carrerform/submit', {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          alert('Form submitted successfully.');
          // Reset form fields
          setfirstname('');
          setlastname('');
          setemail('');
          setPhone('');
          setLocation('');
          setFile(null);
        } else {
          alert('Failed to submit the form. Please try again.');
        }
      } catch (error) {
        console.error('Error submitting the form:', error);
        alert('An error occurred. Please try again.');
      }
    }
  };

  return (
    <>
      <section className="ApplyForm">
        <div className="form-container container">
          <form id="authForm" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="first-name">First Name:</label>
              <input
                type="text"
                id="first-name"
                value={firstname}
                onChange={(e) => setfirstname(e.target.value)}
                placeholder="Enter the First Name"
                required
                autoFocus
              />
            </div>
            <div className="form-group">
              <label htmlFor="last-name">Last Name:</label>
              <input
                type="text"
                id="last-name"
                value={lastname}
                onChange={(e) => setlastname(e.target.value)}
                placeholder="Enter the Last Name"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setemail(e.target.value)}
                placeholder="Enter the email"
                required
              />
              {emailError && <span id="email-error">{emailError}</span>}
            </div>
            <div className="form-group">
              <label htmlFor="phone">Mobile Number:</label>
              <input
                type="text"
                id="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Enter mobile number"
                required
              />
              {phoneError && <span id="error-message">{phoneError}</span>}
            </div>
            <div className="form-group">
              <label htmlFor="location">Location:</label>
              <input
                type="text"
                id="location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                placeholder="Enter your location"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="file">Upload Resume:</label>
              <input
                type="file"
                id="file"
                onChange={handleFileChange}
                accept=".pdf,.doc,.docx"
                required
              />
            </div>
            <div className="form-group">
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Form;
