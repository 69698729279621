import React from "react";
import { Route, Routes } from "react-router-dom";
import TogetherCreatingValue from '../jsx/TogetherCreatingValues';
import Contact from "../jsx/Contact";
import CallToAction from "../jsx/CallToAction";
import About from '../jsx/About';
import Team from '../jsx/Team';
import Bloge from "../jsx/Bloge";
import PrivacyPolicy from "../jsx/PrivacyPolicy";
import Tearms from "../jsx/Tearms";
import Refunds from "../jsx/Refunds";
import Career from "../jsx/Career";
import Form from '../jsx/Form';
import Service from "../jsx/Services";
import Appdevelopment from "../jsx/AppDevelopment";
import SeoContentWriting from "../jsx/SeoContentWriting";
import UiUxDesign from '../jsx/UiUxDesign';
import WebDevelopment from "../jsx/WebDevelopment";
import Digitalmarketing from "../jsx/DigitalMarketing";
import DesignBranding from "../jsx/DesignBranding";
import LoginForm from "../../MyComponents/Admin/jsx/LoginForm";
import MainHidden from "../../MyComponents/Admin/jsx/MainHidden";
import Openings from "../../MyComponents/Admin/jsx/Openings";
import BlogAdd from "../../MyComponents/Admin/jsx/BlogAdd";

import NotFound from "./NotFound";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<TogetherCreatingValue />} />
      <Route path="/CallToAction" element={<CallToAction />} />

      <Route path="/contact" element={<Contact />} />
      <Route path="/about-us" element={<About />} />
      <Route path="/team" element={<Team />} />
      <Route path="/blog" element={<Bloge />} />

      <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      <Route path="/tearms" element={<Tearms />} />
      <Route path="/refunds" element={<Refunds />} />

      <Route path="/career" element={<Career />} />
      <Route path="/form" element={<Form />} />
      
      <Route path="/services" element={<Service />} />
      <Route path="/services/AppDevelopment" element={<Appdevelopment />} />
      <Route path="/services/SeoContentWriting" element={<SeoContentWriting />} />
      <Route path="/services/UiUxDesign" element={<UiUxDesign />} />
      <Route path="/services/DigitalMarketing" element={<Digitalmarketing />} />
      <Route path="/services/WebDevelopment" element={<WebDevelopment />} />
      <Route path="/services/DesignBranding" element={<DesignBranding />} />

      <Route path="/adminPanel" element={<MainHidden/>} />
      <Route path="/admin" element={<LoginForm />} />
      <Route path="/Openings" element={<Openings />} />
      <Route path="/BlogAdd" element={<BlogAdd />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AppRoutes;
