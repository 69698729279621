import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import Bizkrantilogo from "../img/Bizkrantilogo.png";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../css/Header.css';

export default function Header() {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  // Toggle the collapse state
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  return (
    <section>
      <div className="header-container">
        {/* Contact Info */}
        <div className="header-contact-info">
          <a className='social-link'><i className="bi bi-telephone-fill"></i> +91 7559355593</a>
          <a href="mailto:info@bizkranti.com" className="social-link">
            <i className="bi bi-envelope-fill"></i> info@bizkranti.com
          </a>
        </div>

        {/* Social Media Links */}
        <div className="header-social-media-icon">
          <a href="https://www.facebook.com/bizkranti" target="_blank" rel="noopener noreferrer" className="social-link">
            <i className="bi bi-facebook"></i>
          </a>
          <a href="https://twitter.com/bizkranti" target="_blank" rel="noopener noreferrer" className="social-link">
            <i className="bi bi-twitter"></i>
          </a>
          <a href="https://www.instagram.com/bizkranti_it_innovations/" target="_blank" rel="noopener noreferrer" className="social-link">
            <i className="bi bi-instagram"></i>
          </a>
          <a href="https://www.linkedin.com/company/bizkranti-pvt-ltd/about/" target="_blank" rel="noopener noreferrer" className="social-link">
            <i className="bi bi-linkedin"></i>
          </a>
        </div>
      </div>

      <header>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <a className="navbar-brand" href="/">
            <img style={{ height: '35px', width: '150px' }} src={Bizkrantilogo} alt="BizKranti Logo" />
          </a>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded={!isNavCollapsed}
            aria-label="Toggle navigation"
            onClick={handleNavCollapse}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarNav">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink className="Hdr-btn nav-link" exact to="/" onClick={handleNavCollapse}>
                  <i className="bi bi-house-door-fill"></i> Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="Hdr-btn nav-link" to="/about-us" onClick={handleNavCollapse}>
                  <i className="bi bi-info-circle-fill"></i> About Us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="Hdr-btn nav-link" to="/services" onClick={handleNavCollapse}>
                  <i className="bi bi-tools"></i> Services
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="Hdr-btn nav-link" to="/contact" onClick={handleNavCollapse}>
                  <i className="bi bi-telephone-fill"></i> Contact
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="Hdr-btn nav-link" to="/blog" onClick={handleNavCollapse}>
                  <i className="bi bi-journal"></i> Blog
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </section>
  );
}
