import React from 'react';
import "../css/HeroContaint.css";

import container from "../img/Hero/container_4_x2.svg";
import group from "../img/Hero/group_461_x2.svg";

const MasterScreen = () => {
  return (
    <div className="master-screen">
      <div className="overlap-group">
        <div className="rectangle" />
        <div className="div">Leading</div>
        <div className="text-wrapper-2">Company in India</div>
        <div className="web-development">WEB DEVELOPMENT</div>
        <p className="p">
          Partner with the leading website development and digital marketing company in India. Our expert team delivers
          cutting-edge strategies to drive traffic, enhance brand visibility, and maximize ROI for your business.
        </p>
        <img className="vector" alt="Vector" src={container} />
        <img className="group" alt="Group" src={group} />
      </div>
    </div>
  );
};

export default MasterScreen;
