import React from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';

const PrivacyPolicy = () => {
  return (
    <div className="container mt-5">
      <h1 className="text-center text-dark mb-4">Privacy Policy</h1>
      <p className="text-muted fs-5 text-justify">
        We are committed to protecting the privacy and security of our users’ personal information. This Privacy Policy outlines how we collect, use, and protect the information you provide us when you use our website, software, and services (collectively referred to as “Services”).
      </p>

      <h3 className="text-secondary fs-4 mt-4">Information We Collect</h3>
      <p className="text-muted fs-5 text-justify">
        We may collect the following types of personal information from our users:
      </p>

      <ul className="list-unstyled fs-5 text-justify">
        <li>Name</li>
        <li>Email address</li>
        <li>Mailing address</li>
        <li>Phone number</li>
        <li>Payment information</li>
      </ul>

      <h3 className="text-secondary fs-4 mt-4">How We Use Your Information</h3>
      <p className="text-muted fs-5 text-justify">
        Provide and improve our Services
        Process payments
        Respond to customer inquiries
        Send periodic emails and newsletters
        Analyze website usage and trends
        Detect and prevent fraud
        We will not share your personal information with third parties without your consent, except as required by law.
      </p>

      <h3 className="text-secondary fs-4 mt-4">How We Protect Your Information</h3>
      <p className="text-muted fs-5 text-justify">
        We take reasonable measures to protect the personal information we collect from our users, including the use of secure servers and encryption technologies. However, no data transmission over the internet can be guaranteed to be 100% secure, and we cannot guarantee the security of your personal information.
      </p>

      <h3 className="text-secondary fs-4 mt-4">Cookies and Other Tracking Technologies</h3>
      <p className="text-muted fs-5 text-justify">
        We may use cookies and other tracking technologies to collect non-personal information about your website usage and preferences. You can set your browser to disable cookies or notify you when cookies are being used.
      </p>

      <h3 className="text-secondary fs-4 mt-4">Third-Party Websites</h3>
      <p className="text-muted fs-5 text-justify">
        Our website may contain links to third-party websites, which are not covered by this Privacy Policy. We are not responsible for the privacy practices or content of these websites.
      </p>

      <h3 className="text-secondary fs-4 mt-4">Changes to this Privacy Policy</h3>
      <p className="text-muted fs-5 text-justify">
        We reserve the right to update or modify this Privacy Policy at any time. We will notify our users of any material changes to this policy by posting a notice on our website.
      </p>

      <h3 className="text-secondary fs-4 mt-4">Contact Us</h3>
      <p className="text-muted fs-5 text-justify">
        If you have any questions or concerns about this Privacy Policy or our practices, please contact us at contact@bizkranti.com
      </p>
    </div>
  );
};

export default PrivacyPolicy;
