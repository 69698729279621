import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/MainHidden.css';

const MainHidden = () => {
  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(path);
  };

  return (
    <div className='MainDivPannel'>
      <div className='Sec-divMainDivPannel'>
        <button className='Pannel-btnHiddenPannel' onClick={() => handleClick('/Openings')}>Openings Data</button>  
        <button className='Pannel-btnHiddenPannel' onClick={() => handleClick('/BlogAdd')}>Blog Data</button>  
      </div>
    </div>  
  );
}

export default MainHidden;
