import React, { useState, useEffect } from "react";
import JoditEditor from 'jodit-react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/BlogAdd.css';

const BlogAdd = () => {
    const [showForm, setShowForm] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showBlogData, setShowBlogData] = useState(false);
    const [image, setImage] = useState(null);
    const [heading, setHeading] = useState("");
    const [title, setTitle] = useState("");
    const [blogContent, setBlogContent] = useState("");
    const [id, setId] = useState("");
    const [blogData, setBlogData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        setImage(URL.createObjectURL(file));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('image', document.querySelector('input[type="file"]').files[0]);
        formData.append('title', title);
        formData.append('headline', heading);
        formData.append('blogContent', blogContent);

        try {
            const response = await fetch('https://backend.bizkranti.com/blog/addblog', {
                method: 'POST',
                body: formData,
            });
            const result = await response.json();
            console.log('Response:', result);
            setHeading("");
            setTitle("");
            setBlogContent("");
            setImage(null);
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const handleDeleteSubmit = async () => {
        if (!id) {
            console.error("Please enter a valid ID to delete.");
            return;
        }

        try {
            const response = await fetch(`https://backend.bizkranti.com/blog/${id}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                const result = await response.json();
                console.log('Delete Response:', result);
                setId("");
            } else {
                const errorResult = await response.json();
                console.error('Delete failed:', errorResult.message);
            }
        } catch (error) {
            console.error('Error deleting blog:', error);
        }
    };

    const fetchBlogData = async () => {
        setLoading(true);
        try {
            const response = await fetch('https://backend.bizkranti.com/blog/getallblog');
            if (!response.ok) {
                throw new Error('Failed to fetch blog data');
            }
            const data = await response.json();
            setBlogData(data);
            setError(null);
        } catch (error) {
            console.error('Error fetching blog data:', error);
            setError('Failed to load blog data. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (showBlogData) {
            fetchBlogData();
        }
    }, [showBlogData]);

    const handleBlogClick = (e, id) => {
        e.stopPropagation(); // Prevent any default behavior
        navigate(`/blog/${id}`);
    };

    return (
        <div className="container-Blog">
            <h1 style={{ textAlign: 'center' }}>Blog Page</h1>
            <div className="button-group">
                <button className="toggle-button" onClick={() => setShowForm(!showForm)}>
                    {showForm ? "Hide Form" : "Add Data"}
                </button>
                <button className="toggle-button" onClick={() => setShowDelete(!showDelete)}>
                    {showDelete ? "Hide Delete" : "Delete Data"}
                </button>
                <button 
                    className="fetch-blog-button" 
                    style={{ position: 'absolute', top: 110,right: 10,backgroundColor:'#007bff',color:'white'}} 
                    onClick={() => setShowBlogData(!showBlogData)} 
                    disabled={loading}
                >
                    {showBlogData ? "Hide Blogs" : "Show Blogs"}
                </button>
            </div>

            {showForm && (
                <form onSubmit={handleSubmit} className="form">
                    <div>
                        <label>Upload Image:</label>
                        <input type="file" accept="image/*" onChange={handleImageUpload} />
                        {image && <img src={image} alt="Preview" className="image-preview" />}
                    </div>
                    <div>
                        <label>Headline:</label>
                        <input
                            type="text"
                            value={heading}
                            onChange={(e) => setHeading(e.target.value)}
                            placeholder="Enter Headline"
                        />
                    </div>
                    <div>
                        <label>Title:</label>
                        <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Enter Title"
                        />
                    </div>
                    <JoditEditor
                        value={blogContent}
                        onChange={setBlogContent}
                    />
                    <button type="submit" className="submit-button">Submit</button>
                </form>
            )}

            {showDelete && (
                <div className="delete-section">
                    <label>Delete Data:</label>
                    <input
                        type="text"
                        placeholder="Enter ID to delete"
                        className="delete-input"
                        value={id}
                        onChange={(e) => setId(e.target.value)}
                    />
                    <button onClick={handleDeleteSubmit} className="delete-button">
                        Submit
                    </button>
                </div>
            )}

            {showBlogData && (
                <div className="blog-list">
                    <h2>Blog Data</h2>
                    {error && <p className="error-message">{error}</p>}
                    {!error && blogData.length > 0 ? (
                        <ul>
                            {blogData.map((blog) => (
                                <li key={blog.id}>
                                    <span 
                                        onClick={(e) => handleBlogClick(e, blog.id)} 
                                        className="blog-link"
                                    >
                                        <strong>Title:</strong> {blog.title} <br />
                                        <strong>ID:</strong> {blog.id}
                                    </span>
                                </li>
                            ))}
                        </ul>
                    ) : !error && (
                        <p>No blogs available</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default BlogAdd;
