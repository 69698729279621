import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../css/OVOM.css';

const OVOM = () => {
  const ConsoleText = ({ words = [' Shaping the World ', 'With Bizkranti IT Innovation'], colors = ['#caca0f', '#f7f5b6'] }) => {
    const [displayText, setDisplayText] = useState('');
    const [underscoreVisible, setUnderscoreVisible] = useState(true);
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [letterCount, setLetterCount] = useState(0);
    const [direction, setDirection] = useState(1); // 1 for forward, -1 for backward

    useEffect(() => {
      const intervalId = setInterval(() => {
        const currentWord = words[currentWordIndex];

        if (letterCount === currentWord.length + 1 && direction === 1) {
          setDirection(-1);
        } else if (letterCount === 0 && direction === -1) {
          setDirection(1);
          setCurrentWordIndex((currentWordIndex + 1) % words.length);
        } else {
          setDisplayText(currentWord.substring(0, letterCount));
          setLetterCount(letterCount + direction);
        }
      }, 120);

      return () => clearInterval(intervalId);
    }, [letterCount, direction, currentWordIndex, words]);

    useEffect(() => {
      const underscoreInterval = setInterval(() => {
        setUnderscoreVisible(prev => !prev);
      }, 400);

      return () => clearInterval(underscoreInterval);
    }, []);

    return (
      <div className="console-container container">
        <h1 id="text" style={{ color: colors[currentWordIndex] }}>
          {displayText}
          {underscoreVisible && '|'}
        </h1>
      </div>
    );
  };

  const { ref: cardRef, inView: cardInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div className="VISION-container container my-5 p-4">
      <div className="text-center mb-4">
        <h1 className="text-dark text-white">
          <ConsoleText />
        </h1>
      </div>
      <div className="row pb-5 g-4 text-white">
        <div ref={cardRef} className={`col-md-4 ${cardInView ? 'animate-in' : 'animate-out'}`}>
          <div className={`p-3 rounded VISION-border ${cardInView ? 'OVOM-fade-in' : ''}`}>
            <h4 className="fw-bold">Our Vision</h4>
            <p>
              Our goal is to lead globally in IT by empowering clients to solve their toughest challenges.
              As a leading digital marketing company in India, we aim to broaden our impact by offering innovative services to new markets.
              We will drive value and be a vital investment for businesses in this sector.
            </p>
          </div>
        </div>
        <div ref={cardRef} className={`col-md-4 ${cardInView ? 'animate-in' : 'animate-out'}`}>
          <div className={`p-3 rounded VISION-border ${cardInView ? 'OVOM-fade-in' : ''}`}>
            <h4 className="fw-bold">Our Mission</h4>
            <p>
              The objective behind starting the Bizkranti It innovations Pvt Ltd is to introduce the digital world and provide better facilities to every small and big enterprise in India by making website development, application development, and digital marketing and all digital mediums easy and affordable, and create a digital revolution.
            </p>
          </div>
        </div>
        <div ref={cardRef} className={`col-md-4 ${cardInView ? 'animate-in' : 'animate-out'}`}>
          <div className={`p-3 rounded VISION-border ${cardInView ? 'OVOM-fade-in' : ''}`}>
            <h4 className="fw-bold">Our Values</h4>
            <p>
              Our values focus on innovation, excellence, and customer-centricity.
              We are dedicated to providing advanced solutions with integrity and transparency, building trust-based partnerships.
              Our approach emphasizes collaboration and adaptability to meet clients' evolving needs in the fast-changing digital world.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OVOM;
