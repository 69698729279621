import React from 'react';

const NotFound = () => {
    return (
        <div
            className="d-flex justify-content-center align-items-center"
            style={{
                height: '100vh',
                backgroundColor: '#f8f9fa',
                fontSize: '2rem',
                color: '#343a40',
                textAlign: 'center'
            }}
        >
            <div>
                <h1>404 - Page Not Found</h1>
                <p>Sorry, the page you are looking for does not exist.</p>
            </div>
        </div>
    );
};

export default NotFound;
