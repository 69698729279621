import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Layout from "./MyComponents/Route/Layout";

function App() {
  return (
    <Router>
      <Layout />
    </Router>
  );
}

export default App;




// import React from "react";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import './App.css';
// import Header from "./MyComponents/jsx/Header";
// import PopUp from "./MyComponents/jsx/PopUp";
// import AppRoutes from "./MyComponents/Route/AppRoutes";

// function App() {
//   return (
//     <>
//       <PopUp />
//       <Router>
//         <Header />
//         <AppRoutes />
//       </Router>
//     </>
//   );
// }

// export default App;

