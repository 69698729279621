import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  const handleLogin = () => {

    const validUserId = 'BizkrantiAdmin'; 
    const validPassword = 'Bizkranti@Lalit'; 

    if (userId === validUserId && password === validPassword) {
      navigate('/adminPanel'); 
    } else {
      alert('Invalid credentials, please try again.');
    }
  };

  return (
    <div className="container mt-5" style={{ textAlign: 'center' }}>
      <h2 className="mb-4 text-center">Login Page</h2>
      <div className="card w-25 mx-auto">
        <div className="card-body">
          <div className="mb-3">
            <label htmlFor="userId" className="form-label" style={{float:'left'}}>User ID</label>
            <input 
              type="text" 
              className="form-control" 
              id="userId" 
              value={userId} 
              onChange={(e) => setUserId(e.target.value)} 
              required 
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label"style={{float:'left'}}>Password</label>
            <div className="input-group">
              <input 
                type={showPassword ? 'text' : 'password'} 
                className="form-control" 
                id="password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                required 
              />
            </div>
            <div className="form-check mt-2">
              <input 
                type="checkbox" 
                className="form-check-input" 
                id="showPassword" 
                checked={showPassword} 
                onChange={() => setShowPassword(!showPassword)} 
              />
              <label className="form-check-label" htmlFor="showPassword">Show Password</label>
            </div>
          </div>
          <button className="btn btn-primary btn-block" onClick={handleLogin}>Login</button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
