import React from "react";
import "../css/SoluationSection.css"; 

const SolutionSection = () => {
  return (
    <div className="container-fluid">
      <div className="card1 container mt-5">
        <div className="border" />
        <div className="content10 container">
          <div id="container10">
            <div id="static-flip">
              <div className="text-static">YOUR</div>
              <div className="text-static">OUR</div>
            </div>
            <div id="flip">
              <div className="text-flip">CHALLENGES</div>
              <div className="text-flip">SOLUTIONS</div>
            </div>
          </div>
          <p className="container p12 text-white">
            Ready to elevate your online presence with a custom website
            development that drives results? Contact us today to discuss your
            project and discover how our digital marketing services can help
            your business thrive in the digital world.
          </p>
        </div>
        <span className="bottom-text">BizKranti IT Innovation</span>
      </div>
    </div>
  );
};

export default SolutionSection;
