import React, { useEffect } from 'react';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import TechnologiesWeWork from '../jsx/TechnologiesWeWork';

import WhyDigitalMarketing from '../img/NewsAndArtical/WhyDigitalMarkeing.jpg';
import AIMain from '../img/NewsAndArtical/AIMain.jpg';
import ProfWebsite from '../img/NewsAndArtical/ProfWebsite.jpg';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../css/NewsBlogUpdate.css';

// Article data
const articles = [
  {
    imgSrc: WhyDigitalMarketing,
    alt: "A diagram showcasing the key reasons digital marketing is essential for business success and reaching target audiences.",
    title: "Digital Marketing",
    text: "Reasons to why digital marketing is important for your business",
    link: "/blog"
  },
  {
    imgSrc: AIMain,
    alt: "Robot hands grasping a light bulb, representing creativity, alongside the text artificial intelligence to emphasize AI's role.",
    title: "AI Technology",
    text: "AI Technology Unleashed: A Deep Dive into ChatGPT-4, MidJourney, and the Rise of AI Chatbots",
    link: "/blog"
  },
  {
    imgSrc: ProfWebsite,
    alt: "Elevate your business with a professional website to enhance credibility, attract customers, and drive growth effectively.",
    title: "Professional Website",
    text: "Why Your Business Needs a Professional Website: The Essentials of Website Design and Development",
    link: "/blog"
  }
];

const CounterCard = ({ color, count, label, isInView }) => (
  <div className={`card ${color} rounded`}>
    <div className="d-flex align-items-center justify-content-center">
      <span className="counter-value fs-1">
        {isInView ? (
          <CountUp end={count} duration={2} separator="," />
        ) : (
          '0'
        )}
        +
      </span>
    </div>
    <div className="w-100 d-flex align-items-center justify-content-center">
      <p className=" mb-0 fs-5">{label}</p>
    </div>
  </div>
);

const NewsBlogUpdate = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Ensure the animation triggers only once
    threshold: 0.1, // Trigger when 10% of the component is visible
  });

  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(path);
  };

  const handleScroll = () => {
    const elements = document.querySelectorAll('.NBU-BlogContaint');
    const windowHeight = window.innerHeight;

    elements.forEach(element => {
      const rect = element.getBoundingClientRect();
      if (rect.top < windowHeight * 0.75) {
        element.classList.add('NBU-fade-in');
      } else {
        element.classList.remove('NBU-fade-in');
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Run on initial render

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [TechnologiesWeWork]);
    

  return (
    <>
      <section className="container-fluid NewsBlogUpdate" style={{ backgroundColor: '#14234c', color: 'white' }}>
        <h1 className="text-center text-white pt-4">
          Latest News & <span className="text-warning">Article</span>
        </h1>
        <div className="NBU-container container mt-5">
          <div className="row">
            {articles.map((article, index) => (
              <div className="col-md-4 mb-4 NBU-BlogContaint" key={index}>
                <div className="NBU-card border-0">
                  <div className="position-relative" onClick={() => handleClick(article.link)}>
                    <img src={article.imgSrc} className="NBU-card-img-top" alt={article.alt} />
                  </div>
                  <div className="NBU-card-body" onClick={() => handleClick(article.link)}>
                    <h3 className="NBU-card-title">{article.title}</h3>
                    <p className="NBU-card-text">
                      {article.text.split(" ").map((word, idx) =>
                        word.toLowerCase().startsWith("why") || word.toLowerCase().startsWith("ai") || word.toLowerCase().startsWith("professional") ? (
                          <span key={idx}>{word} </span>
                        ) : (
                          `${word} `
                        )
                      )}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
        <h1 className='text-center'>
          <button 
        className=' text-dark pt-4 border-bottom border-5'>Successful Outcomes </button></h1>
      <div className="cards d-flex flex-wrap justify-content-center" ref={ref}>
        <CounterCard color="red" count={320} label="Project Complete" isInView={inView} />
        <CounterCard color="blue" count={4} label="Years Of Experience" isInView={inView} />
        <CounterCard color="green" count={250} label="Happy Clients" isInView={inView} />
      </div>
    </>
  );
};

export default NewsBlogUpdate;
