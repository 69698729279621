import React, { useState, useEffect, useRef } from 'react';
import Location from '../img/contact/location.png';
import Mail from '../img/contact/mail.png';
import Call from '../img/contact/call.png';
import Fb from '../img/contact/facebook.png';
import Insta from '../img/contact/instagram.png';
import Twitter from '../img/contact/twitter.png';
import Linkin from '../img/contact/linkedin.png';
import { useNavigate } from 'react-router-dom'; 
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../css/Contact.css';
import Footer from './Footer';

function Contact() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    selectedOption: '',
    message: ''
  });

  const [submitStatus, setSubmitStatus] = useState('');
  const fadeInSectionRefs = useRef([]);
  const navigate = useNavigate(); 

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://backend.bizkranti.com/contact-save/save', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          selectedOption: '',
          message: ''
        });
        setSubmitStatus('Your message has been sent successfully!');
      } else {
        setSubmitStatus('Failed to submit the form.');
      }
    } catch (error) {
      console.error('Network error or server issue:', error);
      setSubmitStatus('There was an error sending your message. Please try again later.');
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    fadeInSectionRefs.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      fadeInSectionRefs.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section>
        <div className="container-fluid CONTACTNAME mb-auto mt-auto fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
          <button className="CT-BTN">
            <h1>Get In <span style={{ color: "#fcc101" }}> Touch </span></h1>
            <span className="CT-BTN-SPN"></span>
          </button>
        </div>

        <div className='container-fluid fade-in-section' ref={(el) => fadeInSectionRefs.current.push(el)}>
          <div className='contact-main-page'>
            <div className="contact-container container">
              <div className="contactInfo text-black fs-5">
                <div>
                  <h2 className="text-center">Contact Info</h2>
                  <ul className="info">
                    <li>
                      <span className="contact-span">
                        <img src={Location} alt="location" />
                      </span>
                      <span className="contact-span">
                        K VIDYA NAGAR SUTARWADA FAIZPUR, YAWAL, JALGAON
                        <br />
                        GAT NO 922/2, SURVEY NO3782
                      </span>
                    </li>
                    <li>
                      <span className="contact-span">
                        <img src={Mail} alt="mail" />
                      </span>
                      <span className="contact-span">
                        <a href="mailto:info@bizkranti.com">info@bizkranti.com</a>
                      </span>
                    </li>
                    <li>
                      <span className="contact-span">
                        <img src={Call} alt="call" />
                      </span>
                      <span className="contact-span">7559355593</span>
                    </li>
                  </ul>
                </div>
                <ul className="sci">
                  <li>
                    <a href="https://www.facebook.com/bizkranti" target="_blank" rel="noopener noreferrer">
                      <img src={Fb} alt="Facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/bizkranti_it_innovations/" target="_blank" rel="noopener noreferrer">
                      <img src={Insta} alt="Instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="https://x.com/bizkranti" target="_blank" rel="noopener noreferrer">
                      <img src={Twitter} alt="Twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/bizkranti-pvt-ltd/" target="_blank" rel="noopener noreferrer">
                      <img src={Linkin} alt="LinkedIn" />
                    </a>
                  </li>
                </ul>
              </div>

              <div className="contactForm">
                <h2>Send a Message</h2>
                <form className="formBox" onSubmit={handleSubmit}>
                  <div className="inputBox w50">
                    <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />
                    <span className="contact-span text-dark">First Name</span>
                  </div>
                  <div className="inputBox w50">
                    <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
                    <span className="contact-span text-dark">Last Name</span>
                  </div>
                  <div className="inputBox w50">
                    <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                    <span className="contact-span text-dark">Email Address</span>
                  </div>
                  <div className="inputBox w50">
                    <input type="text" name="phone" value={formData.phone} onChange={handleChange} /> {/* Removed required */}
                    <span className="contact-span text-dark">Mobile Number (optional)</span>
                  </div>
                  <div className="container dropdown-container inputBox">
                    <label htmlFor="options" style={{ fontSize: '20px' }}>Choose an option:</label>
                    <select
                      id="options"
                      name="selectedOption"
                      value={formData.selectedOption}
                      onChange={handleChange}
                      className="dropdown text-center"
                      style={{ width: '185px', height: '35px' }}
                    >
                      <option value="">Select an option</option>
                      <option value="Web Development">Web Development</option>
                      <option value="Digital Marketing">Digital Marketing</option>
                      <option value="Design & Branding">Design & Branding</option>
                      <option value="UI/UX Designing">UI/UX Designing</option>
                      <option value="SEO & Content">SEO & Content</option>
                      <option value="App Development">App Development</option>
                    </select>
                  </div>
                  <div className="inputBox w100">
                    <textarea name="message" value={formData.message} onChange={handleChange} /> {/* Removed required */}
                    <span className="contact-span text-dark">Write your message here... (optional)</span>
                  </div>
                  <div className="inputBox w100">
                    <input type="submit" value="Send" />
                  </div>
                </form>
                {submitStatus && <p>{submitStatus}</p>}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Contact;
