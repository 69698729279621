import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import Footer from '../jsx/Footer';
import { Helmet } from 'react-helmet';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../css/Bloge.css';

const Blodge = () => {
    const [content, setContent] = useState([]);
    const [recentUpdates, setRecentUpdates] = useState([]);
    const [expandedSections, setExpandedSections] = useState({});
    const [showSinglePost, setShowSinglePost] = useState(null);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await fetch('https://backend.bizkranti.com/blog/getblog');
                const data = await response.json();
                if (Array.isArray(data)) {
                    setContent(data);
                    const updatedRecentUpdates = data.map(item => item.title || 'No Title Available');
                    setRecentUpdates(updatedRecentUpdates);
                } else {
                    setContent([]);
                }
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };
        
        fetchBlogs();

        const hash = location.hash.substring(1000000);
        if (hash) {
            setShowSinglePost(hash);
            const element = document.getElementById(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location.hash]);

    const toggleExpandSection = (index) => {
        setExpandedSections(prevState => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const scrollToSection = (id) => {
        setShowSinglePost(id);
        navigate(`/blog#${id}`);
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleShareLink = (item) => {
        const shareUrl = `${window.location.origin}/blog#${item.id}`;
        const message = `${item.title} ${shareUrl}`;

        if (navigator.share) {
            navigator.share({
                title: item.title,
                url: shareUrl,
            }).then(() => {
                console.log('Share successful');
            }).catch((error) => {
                console.error('Share failed:', error);
            });
        } else {
            const encodedMessage = encodeURIComponent(message);
            const whatsappUrl = `https://wa.me/?text=${encodedMessage}`;
            window.open(whatsappUrl, '_blank');
        }
    };

    return (
        <div className="container-fluid MainScreen">
            <Helmet>
                <title>{showSinglePost ? content.find(item => item.id === showSinglePost)?.title : 'Blog Page'}</title>
                {showSinglePost && (
                    <meta property="og:image" content={content.find(item => item.id === showSinglePost)?.imageUrl} />
                )}
            </Helmet>
            <div className="row">
                <div className="col-lg-9">
                    <div className="container MothiSCreen">
                        {content.map((item, index) => (
                            (showSinglePost === null || showSinglePost === item.id) && (
                                <div key={item.id} id={item.id} className="Pages" style={{ margin: '10px auto', border: 'solid' }}>
                                    <div className="row text-left">
                                        <div className="col-md-4">
                                            <img src={item.imageUrl} alt={`Main ${item.id}`} className="MainIMG img-fluid" />
                                        </div>
                                        <div className="col-md-8 mt-5 position-relative">
                                            {/* Render the headline here */}
                                            <h3>{item.headline || 'No Headline Available'}</h3>
                                            <h5>
                                                <Link to={`/blog#${item.id}`} onClick={() => scrollToSection(item.id)}>
                                                    {item.title || 'No Title Available'}
                                                </Link>
                                            </h5>
                                            <button className="btn btn-link position-absolute top-0" style={{ right: '25px' }} onClick={() => handleShareLink(item)} title="Share this link">
                                                <i className="bi bi-share-fill" style={{ fontSize: '1.5rem' }}></i>
                                            </button>
                                            <div className="text-content">
                                                <div dangerouslySetInnerHTML={{ __html: expandedSections[item.id] ? item.blogContent : `${item.blogContent.substring(0, 500)}...` }} />
                                                {!expandedSections[item.id] && <button className="ReadMore" onClick={() => toggleExpandSection(item.id)}>Read More</button>}
                                                {expandedSections[item.id] && <button className="show-less-btn" onClick={() => toggleExpandSection(item.id)}>Show Less</button>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        ))}
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="sidebar">
                        <div className="RecentUpdate p-3 mb-3">
                            <h2 className="font-weight-bold">Recent Update</h2>
                            {recentUpdates.map((item, index) => (
                                <button key={index} className="RecentUpdate-button btn btn-link text-left p-0" onClick={() => scrollToSection(content[index]?.id)}>
                                    {item}
                                </button>
                            ))}
                        </div>
                        <div className="NewsHeader p-3">
                            <h2 className="font-weight-bold text-left">News Headline</h2>
                            {content.map((item, index) => (
                                <button key={index} className="NewsHeader-button btn btn-link text-left p-0" onClick={() => scrollToSection(item.id)}>
                                    {item.headline || 'No Headline Available'}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Blodge;



// import React, { useState, useEffect } from 'react';
// import { useLocation, useNavigate, Link } from 'react-router-dom';
// import Footer from '../jsx/Footer';
// import { Helmet } from 'react-helmet';
// import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import '../css/Bloge.css';

// const Blodge = () => {
//     const [content, setContent] = useState([]);
//     const [recentUpdates, setRecentUpdates] = useState([]);
//     const [expandedSections, setExpandedSections] = useState({});
//     const [showSinglePost, setShowSinglePost] = useState(null);
//     const location = useLocation();
//     const navigate = useNavigate();

//     useEffect(() => {
//         const fetchBlogs = async () => {
//             try {
//                 const response = await fetch('https://backend.bizkranti.com/blog/getblog');
//                 const data = await response.json();
//                 if (Array.isArray(data)) {
//                     setContent(data);
//                     const updatedRecentUpdates = data.map(item => item.title || 'No Title Available');
//                     setRecentUpdates(updatedRecentUpdates);
                    
//                     const hash = location.hash.substring(10000); 
//                     if (hash) {
//                         setShowSinglePost(hash);
//                         const element = document.getElementById(hash);
//                         if (element) {
//                             element.scrollIntoView({ behavior: 'smooth' });
//                         }
//                     }
//                 } else {
//                     setContent([]);
//                 }
//             } catch (error) {
//                 console.error('Error fetching blogs:', error);
//             }
//         };

//         fetchBlogs();
//     }, [location.hash]);

//     const toggleExpandSection = (index) => {
//         setExpandedSections(prevState => ({
//             ...prevState,
//             [index]: !prevState[index],
//         }));
//     };

//     const scrollToSection = (id) => {
//         setShowSinglePost(id);
//         navigate(`/blog#${id}`);
//         const element = document.getElementById(id);
//         if (element) {
//             element.scrollIntoView({ behavior: 'smooth' });
//         }
//     };

//     const handleShareLink = (item) => {
//         const shareUrl = `${window.location.origin}/blog#${item.id}`;
//         const message = `${item.title} ${shareUrl}`;

//         if (navigator.share) {
//             navigator.share({
//                 title: item.title,
//                 url: shareUrl,
//             }).then(() => {
//                 console.log('Share successful');
//             }).catch((error) => {
//                 console.error('Share failed:', error);
//             });
//         } else {
//             const encodedMessage = encodeURIComponent(message);
//             const whatsappUrl = `https://wa.me/?text=${encodedMessage}`;
//             window.open(whatsappUrl, '_blank');
//         }
//     };

//     return (
//         <div className="container-fluid MainScreen">
//             <Helmet>
//                 <title>{showSinglePost ? content.find(item => item.id === showSinglePost)?.title : 'Blog Page'}</title>
//                 {showSinglePost && (
//                     <meta property="og:image" content={content.find(item => item.id === showSinglePost)?.imageUrl} />
//                 )}
//             </Helmet>
//             <div className="row">
//                 <div className="col-lg-9">
//                     <div className="container MothiSCreen">
//                         {content.map((item, index) => (
//                             (showSinglePost === null || showSinglePost === item.id) && (
//                                 <div key={item.id} id={item.id} className="Pages" style={{ margin: '10px auto', border: 'solid' }}>
//                                     <div className="row text-left">
//                                         <div className="col-md-4">
//                                             <img src={item.imageUrl} alt={`Main ${item.id}`} className="MainIMG img-fluid" />
//                                         </div>
//                                         <div className="col-md-8 mt-5 position-relative">
//                                             <h3>{item.headline || 'No Headline Available'}</h3>
//                                             <h5>
//                                                 <Link to={`/blog#${item.id}`} onClick={() => scrollToSection(item.id)}>
//                                                     {item.title || 'No Title Available'}
//                                                 </Link>
//                                             </h5>
//                                             <button className="btn btn-link position-absolute top-0" style={{ right: '25px' }} onClick={() => handleShareLink(item)} title="Share this link">
//                                                 <i className="bi bi-share-fill" style={{ fontSize: '1.5rem' }}></i>
//                                             </button>
//                                             <div className="text-content">
//                                                 <div dangerouslySetInnerHTML={{ __html: expandedSections[item.id] ? item.blogContent : `${item.blogContent.substring(0, 500)}...` }} />
//                                                 {!expandedSections[item.id] && <button className="ReadMore" onClick={() => toggleExpandSection(item.id)}>Read More</button>}
//                                                 {expandedSections[item.id] && <button className="show-less-btn" onClick={() => toggleExpandSection(item.id)}>Show Less</button>}
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             )
//                         ))}
//                     </div>
//                 </div>
//                 <div className="col-lg-3">
//                     <div className="sidebar">
//                         <div className="RecentUpdate p-3 mb-3">
//                             <h2 className="font-weight-bold">Recent Update</h2>
//                             {recentUpdates.map((item, index) => (
//                                 <button key={index} className="RecentUpdate-button btn btn-link text-left p-0" onClick={() => scrollToSection(content[index]?.id)}>
//                                     {item}
//                                 </button>
//                             ))}
//                         </div>
//                         <div className="NewsHeader p-3">
//                             <h2 className="font-weight-bold text-left">News Headline</h2>
//                             {content.map((item, index) => (
//                                 <button key={index} className="NewsHeader-button btn btn-link text-left p-0" onClick={() => scrollToSection(item.id)}>
//                                     {item.headline || 'No Headline Available'}
//                                 </button>
//                             ))}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <Footer />
//         </div>
//     );
// };

// export default Blodge;
