// import React from "react";
// import { useLocation } from "react-router-dom";
// import Header from "../jsx/Header";
// import PopUp from "../jsx/PopUp";
// import AppRoutes from "./AppRoutes";
// import NotFound from "./NotFound";

// function Layout({ children }) {
//   const location = useLocation();

//   // Check if the current route matches the NotFound route
//   const isFound = location.pathname === "/" || location.pathname === "/contact"||location.pathname === "/CallToAction"||
//   location.pathname === "/about-us"||location.pathname === "/team"||location.pathname === "/blog"||location.pathname === "/privacypolicy"||
//   location.pathname === "/tearms"||location.pathname === "/refunds"||location.pathname === "/career"||location.pathname === "/form"||
//   location.pathname === "/services"||location.pathname === "/services/AppDevelopment"||location.pathname === "/services/WebDevelopment"||
//   location.pathname === "/services/DesignBranding"||location.pathname === "/services/DigitalMarketing"||location.pathname === "/services/UiUxDesign"||
//   location.pathname === "/services/SeoContentWriting"||location.pathname === "/adminPanel"||location.pathname === "/admin"||location.pathname === "/Openings"||
//   location.pathname === "/BlogAdd";

//   return (
//     <>
//       {isFound ? (
//         <>
//         <Header />
//         <PopUp />
//         <AppRoutes />
//         {children}
//       </>
//       ) : (
        
//         <NotFound />
//       )}
//     </>
//   );
// }

// export default Layout;


import React from "react";
import { useLocation } from "react-router-dom";
import Header from "../jsx/Header";
import PopUp from "../jsx/PopUp";
import AppRoutes from "./AppRoutes";
import NotFound from "./NotFound";

function Layout({ children }) {
  const location = useLocation();

  // Check if the current route matches the allowed routes or dynamic blog pages
  const isFound =
    location.pathname === "/" ||
    location.pathname === "/contact" ||
    location.pathname === "/CallToAction" ||
    location.pathname === "/about-us" ||
    location.pathname === "/team" ||
    location.pathname === "/blog" ||
    location.pathname === "/privacypolicy" ||
    location.pathname === "/tearms" ||
    location.pathname === "/refunds" ||
    location.pathname === "/career" ||
    location.pathname === "/form" ||
    location.pathname === "/services" ||
    location.pathname === "/services/AppDevelopment" ||
    location.pathname === "/services/WebDevelopment" ||
    location.pathname === "/services/DesignBranding" ||
    location.pathname === "/services/DigitalMarketing" ||
    location.pathname === "/services/UiUxDesign" ||
    location.pathname === "/services/SeoContentWriting" ||
    location.pathname === "/adminPanel" ||
    location.pathname === "/admin" ||
    location.pathname === "/Openings" ||
    location.pathname === "/BlogAdd" ||
    location.pathname.startsWith("/blog") || // Handle dynamic blog pages

    // Handle blog pages with hashes like /blog#subpage
    (location.pathname === "/blog" && location.hash);

  return (
    <>
      {isFound ? (
        <>
          <Header />
          <PopUp />
          <AppRoutes />
          {children}
        </>
      ) : (
        <NotFound />
      )}
    </>
  );
}

export default Layout;
