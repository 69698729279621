import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Importing Images
import Development from '../img/servicesIMG/WebDevelopment/WebDevelopment.png';
import WebsiteDesign from '../img/servicesIMG/WebDevelopment/WebsiteDesign.png';
import CustomSiteDevelopment from '../img/servicesIMG/WebDevelopment/CustomSiteDevelopment.png';
import SEOOptimizationRemovebgPreview from '../img/servicesIMG/WebDevelopment/SEOOptimizationRemovebgPreview.png';
import SiteUpdateNRvamp from '../img/servicesIMG/WebDevelopment/SiteUpdateNRvamp.png';
import WebUpkeepNSupport from '../img/servicesIMG/WebDevelopment/WebUpkeepNSupport.png';
import ECommerceDevelopment from '../img/servicesIMG/WebDevelopment/E-commerceDevelopment.png';

// Web Development Images
import html from '../img/technologies/html.png';
import css from '../img/technologies/css.png';
import javaScript from '../img/technologies/javaScript.png';
import nodeJS from '../img/technologies/nodeJS.png';
import nextJS from '../img/technologies/nextJS.png';
import Vue from '../img/technologies/Vue.png';
import angularJS from '../img/technologies/angularJS.png';
import java from '../img/technologies/java.png';
import python from '../img/technologies/python.png';
import php from '../img/technologies/php.png';

import Footer from './Footer';

import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../css/WebDevelopment.css';

const WebDevelopment = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const fadeInSectionRefs = useRef([]);

    const [selectedService, setSelectedService] = useState(null);
    const [activeFAQ, setActiveFAQ] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    const technologies = [
        {
            img: html, title: "HTML", description: `HTML= (Hypertext Markup Language) 
            Type: Markup Language 
            Use: Front-End Development (Content Structure) 
            Importance: Essential for creating the structure of web pages.`
        },
        {
            img: css, title: "CSS", description: `CSS (Cascading Style Sheets)
                Type: Style Sheet Language
                Use: Front-End Development (Design and Layout)
                Importance: Provides the styling for web pages, enhancing user experience.`
        },
        {
            img: javaScript, title: "JavaScript", description: `JavaScript
                Type: Scripting Language
                Use: Front-End and Back-End Development (Interactivity and Logic)
                Importance: Adds dynamic functionality and interactivity to websites.`
        },
        {
            img: nodeJS, title: "NodeJS", description: `Node.js (JavaScript)
                Type: Runtime Environment
                Use: Full-Stack Development (Server-Side JavaScript)
                Importance: Enables JavaScript to be used for both client-side and server-side development.`
        },
        {
            img: nextJS, title: "NextJS", description: `Type: React Framework
                Use: Full-Stack Development (Server-Side Rendering and Static Sites)
                Importance: Extends React.js to provide server-side rendering, static site generation, and full-stack capabilities.`
        },
        {
            img: Vue, title: "VueJS", description: `Vue.js
                Type: JavaScript Framework
                Use: Front-End Development (User Interfaces)
                Importance: Provides a flexible and progressive framework for building user interfaces and single-page applications.`
        },
        {
            img: angularJS, title: "AngularJS", description: `AngularJS
                Type: JavaScript Framework
                Use: Front-End Development (Web Applications)
                Importance: Provides a comprehensive solution for building large-scale, enterprise-grade applications.`
        },
        {
            img: java, title: "Java", description: `Java
                Type: Object-Oriented Programming Language
                Use: Back-End Development (Enterprise-Level Web Applications)
                Importance: Provides scalability and robustness for large-scale applications.`
        },
        {
            img: python, title: "Python", description: `Python
                Type: High-Level Programming Language
                Use: Back-End Development (Web Applications and APIs)
                Importance: Known for its simplicity and versatility, ideal for rapid development.`
        },
        {
            img: php, title: "PHP", description: `PHP (Hypertext Preprocessor)
                Type: Server-Side Scripting Language
                Use: Back-End Development (Dynamic Content and Server Communication)
                Importance: Powers a large portion of the web, especially content management systems.`
        },
    ];

    const services = [
        { img: WebsiteDesign, title: "Website Design", description: "Crafting beautiful and responsive websites." },
        { img: WebUpkeepNSupport, title: "Web Upkeep & Support", description: "Ongoing maintenance and support for your website." },
        { img: SiteUpdateNRvamp, title: "Site Update & Revamp", description: "Refreshing and updating your existing website." },
        { img: SEOOptimizationRemovebgPreview, title: "SEO Optimization", description: "Enhancing your website's visibility on search engines." },
        { img: CustomSiteDevelopment, title: "Custom Site Development", description: "Tailor-made solutions for your unique business needs." },
        { img: ECommerceDevelopment, title: "ECommerce Development", description: "Building robust online stores to boost your sales." },
    ];

    const faqItems = [
        { question: 'What is web development?', answer: 'Web development is the process of building, creating, and maintaining websites. It involves various aspects such as web design, web programming, and database management.' },
        { question: 'How do you ensure a website is mobile-friendly?', answer: 'We use responsive design techniques to ensure your website adapts seamlessly to all devices, from smartphones to desktops. This guarantees a smooth user experience and higher SEO rankings.' },
        { question: 'How do you handle website security?', answer: 'We implement industry-leading security practices, including SSL certificates, data encryption, and regular security updates to protect your site from vulnerabilities and cyber threats.' },
        { question: 'What does ‘development’ entail in web development?', answer: 'Web development involves designing a site and integrating back-end programming. The objective is often to build a database-driven site that allows dynamic content updates. Macronimous tailors solutions to meet your unique business requirements, whether you need a simple catalog site or a complex eCommerce platform.' },
        { question: 'How are web development and web design different?', answer: 'Web design is the process of creating a website, while web development introduces interactive and dynamic functions to it. If you need your site to frequently update or interact with the user, you’d want web development and a database-driven site.' }
    ];

    const showDetails = (service) => setSelectedService(service);
    const hideDetails = () => setSelectedService(null);
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 3) % technologies.length);
        }, 5000);
        return () => clearInterval(interval);
    }, [technologies.length]);

    const displayedItems = technologies.slice(currentIndex, currentIndex + 4);

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.replace('#', ''));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location.hash]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('visible');
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        fadeInSectionRefs.current.forEach((section) => {
            if (section) {
                observer.observe(section);
            }
        });

        return () => {
            fadeInSectionRefs.current.forEach((section) => {
                if (section) observer.unobserve(section);
            });
        };
    }, []);

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.replace('#', ''));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            window.scrollTo(0, 0);
        }
    }, [location.hash]);

    return (
        <>
            <section className="WD-title-section text-center py-5 fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
                <div className="WD-container container">
                    <h1 className="text-center">Web <span style={{ color: '#fcc101' }}>Development</span></h1>
                    <p className="text-center" style={{ color: 'black' }}>
                        At BizKranti, we provide comprehensive web development solutions to bring your vision to life.
                    </p>
                </div>
            </section>
            <section className="WD-services" id="services">
                <h1 className="text-white text-center">Our <span style={{ color: '#fcc101' }}>Services</span></h1>
                <div className="WD-container-fluid d-flex align-items-center justify-content-between py-5">
                    <div className="WD-services-content">
                        <div className="WD-services-image">
                            <img src={Development} alt="A man in front of a computer featuring HTML and web development icons, emphasizing the field of web design and development." />
                        </div>
                        <div className="WD-service-boxes">
                            {services.map((service, index) => (
                                <div key={index} className="WD-service-box zoom-in" onClick={() => showDetails(service.title)}>
                                    <div className="WD-services-image1">
                                        <img src={service.img} alt={service.title} />
                                    </div>
                                    <h3 className="fs-4"><b>{service.title}</b></h3>
                                    <p>{service.description}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            {selectedService && (
                <section className="WD-details" id="details">
                    <div className="WD-details-content show">
                        <h2 id="details-title">{selectedService}</h2>
                        <p id="details-description">
                            {selectedService === 'Website Design' && 'Craft a modern and visually appealing website with a focus on user experience. Ensure intuitive navigation, a responsive layout for various devices, and a cohesive design that reflects your brand. High-quality images and clear typography are essential for creating an engaging and user-friendly interface.'}
                            {selectedService === 'Custom Site Development' && 'Develop bespoke websites tailored to specific business needs and goals. This involves creating custom features, unique design elements, and specialized functionality that align with your brand’s requirements and enhance the site’s overall effectiveness.'}
                            {selectedService === 'SEO Optimization' && 'Improve your website\'s visibility in search engines through effective SEO practices. Incorporate relevant keywords, optimize meta tags, and produce high-quality content to boost search rankings. Regularly review and update SEO strategies to stay competitive in search results.'}
                            {selectedService === 'Site Update & Revamp' && 'Refresh your website’s design and functionality to enhance performance and usability. This involves updating the visual layout, reorganizing content for better accessibility, and adding new features to improve overall user experience. Aim for a design that is both modern and easy to navigate.'}
                            {selectedService === 'Web Upkeep & Support' && 'Provide ongoing maintenance and technical support to ensure your website operates smoothly. This includes regular performance checks, software updates, security patches, and troubleshooting any issues that arise. Reliable upkeep is crucial for maintaining site integrity and user satisfaction.'}
                            {selectedService === 'ECommerce Development' && 'Build and optimize online stores with secure payment systems, user-friendly interfaces, and efficient product management tools. Focus on providing a seamless shopping experience with easy navigation, reliable transactions, and effective customer support.'}
                        </p>
                        <button className="bt1" onClick={hideDetails}>Close</button>
                        <a href="#"><button className="bt2" onClick={() => navigate('/contact')}>Enquire Us</button></a>
                    </div>
                </section>
            )}
            <section className="WD-TechnologiesWeWork text-center py-5 fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
                <div className='WD-TechnologiesWeWork-mainheader' id='WebTechnologies'>
                    <h1>Technologies <span style={{ color: '#fcc101' }}>We Work</span></h1>
                    <p>We are creating projects with all these technologies.</p>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        {displayedItems.map((tech, index) => (
                            <div key={index} className="col-md-6 mb-4">
                                <div className="WD-TechnologiesWeWork-div">
                                    <img src={tech.img} alt={tech.title} className="WD-TechnologiesWeWork-img" />
                                    <div className="WD-TechnologiesWeWork-content">
                                        <h5>{tech.title}</h5>
                                        <p>{tech.description}</p>
                                        <button className="WD-TechnologiesWeWork-button btn btn-primary ml-auto mr-auto" onClick={() => navigate('/blog#WebDevelopment')}>Read More</button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* FAQ Section */}
            <section id="FAQ" className="WD-FAQ fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
                <div className="WD-faq-footer">
                    <h2 className="WD-faq-header">
                        Frequently <span style={{ color: '#fcc101' }}>Asked Questions</span>
                    </h2>
                    {faqItems.map((item, index) => (
                        <div className="WD-faq-item" key={index}>
                            <button
                                className="WD-faq-question"
                                onClick={() => toggleFAQ(index)}
                                aria-expanded={activeIndex === index}
                            >
                                <span className="WD-faq-symbol-circle">
                                    <span className="faq-symbol">{activeIndex === index ? '-' : '+'}</span>
                                </span>
                                {item.question}
                            </button>
                            <div className={`WD-faq-answer ${activeIndex === index ? 'd-block' : 'd-none'}`}>
                                <p>{item.answer}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

                        <section>
                 <div className="container-fluid WD-CallToAction text-center p-5 fade-in-section" ref={(el) => fadeInSectionRefs.current.push(el)}>
                     <h1 className='text-white text-center'>
                     Let's Get <span style={{ color: '#ffc101' }}> Started </span>
                     </h1>
                     <h4 className="text-white text-center">
                     Bring Your Brand to Life with a Stunning Website design Get a Quote Today
                     </h4>
                     <button type="button" className="btn btn-warning mt-4" onClick={() => navigate('/contact')}>
                         CONTACT WITH US
                     </button>
                 </div>
             </section>
          <Footer />
        </>
    );
};

export default WebDevelopment;
