import React from 'react';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';

const TermsAndConditions = () => {
  return (
    <div className="container mt-5">
      <h1 className="display-4 text-center">TERMS AND CONDITIONS</h1>

      <div className="mt-4">
        <h3>1. Definitions</h3>
        <p>
          The following Terms and Conditions document is a legal agreement between Bizkranti It Innovations Pvt. Ltd, hereafter the "Developer", and "the Client" for the purposes of website design or development. These Terms and Conditions set forth the provisions under which the Client may use the services supplied.
          The Developer is an Internet web design provider offering the Client graphical design, HTML, CSS, Javascript, PHP, WordPress (and WooCommerce) and other related computer programming languages.
        </p>
        <h3>2. Acceptance of Work</h3>
        <p>
          Quotations are valid for 30 days from the date of issue. When the Client places an order to purchase a website or website updates from the Developer, the order represents an offer to the Developer to purchase the website or website updates. No contract for the supply of services exists between Client and Developer until the Developer sends an invoice to the Client for payment. The invoice equals acceptance by the Developer (or third party developer) of the Client’s offer to purchase services from the Developer and this acceptance of work is a valid contract between Client and Developer regardless of whether the Client receives the invoice.
        </p>
        <p>
          Any other services on the order that have not been included in the invoice do not form part of the contract. The Client agrees to check that the details of the invoice are correct and should print and keep a copy for their records. The Developer is liable to withdraw from the contract at any time prior to acceptance.
        </p>
        <p>
          Additional work requested by the Client that is not specified in the agreed quotation is subject to an additional quotation by the Developer on receipt of the specification. If the work is needed as part of an existing project, then this may affect time scale and overall delivery time of the project.
        </p>
        <p>
          If a functional specification and a set of testing criteria are included within the quotation, the Developer is responsible for fulfilling the testing criteria as the sole criteria for completion of the contract.
        </p>
        <p>
          The Client agrees that the standard development platform (available upon request or at our website <a href="https://Bizkranti-website-design.uk/terms_and_conditions/">https://Bizkranti-website-design.uk/terms_and_conditions/</a>) is an agreeable platform for the development of the website and all acceptance testing will occur only on the standard development platform. The Client further agrees that any requests relating to hardware or software outside the standard development platform will be deemed additional work.
        </p>
        <p>
          The Client agrees to provide any needed information and content required by the Developer in good time to enable the Developer to complete a design or website work as part of an agreed project.
        </p>
        <p>
          Please note: the client has the sole responsibility for adding site content. Bizkranti does not add content to a website (web pages, products etc.). Bizkranti provides the working website and a Content Management System which is designed for the client to make full use of and add material themselves. Should an agreement be made between Bizkranti and the client for the inclusion of content then the following rules apply:
        </p>
        <ul>
          <li>All content must be provided in a timely fashion. If the content is not given quickly enough then Bizkranti will complete the website and the CMS with basic templates requiring the client to add the content themselves. The project will then be treated as completed and all project fees will be owed.</li>
          <li>Once content has been added by Bizkranti then no further alterations will be made by Bizkranti. All additional changes will be chargeable.</li>
          <li>Bizkranti is not responsible for proofreading the content or checking for incorrect information; this is the sole responsibility of the client.</li>
          <li>Content CAN NOT be taken from other websites. Bizkranti will not check if the content provided is taken from other sources.</li>
        </ul>
        <h3>3. Permission and Copyright</h3>
        <p>
          Copyright of the completed web designs, images, pages, code, and source files created by the Developer for the project shall be with the Client upon final payment only by prior written agreement. Without an agreement, ownership of designs and all code is with the Developer.
        </p>
        <p>
          These terms of use grant a non-exclusive limited license so that the Client can use the design on one website on one domain name only. The Client is not permitted to use a design for more than one website without prior written agreement between the Client and the Developer.
        </p>
        <p>
          The Client agrees that resale or distribution of the completed files is forbidden unless a prior written agreement is made between the Client and the Developer.
        </p>
        <p>
          The Client hereby agrees that all media and content made available to the Developer for use in the project are either owned by the Client or used with full permission of the original authors. The Client agrees to hold harmless, protect and defend the Developer from any claim or suit that may arise as a result of using the supplied media and content.
        </p>
        <p>
          The Client agrees that the Developer may include development credits and links within any code the Developer designs, builds or amends. If the Developer designs a website for a Client, then the Client agrees that the Developer may include a development credit and link displayed on the Client’s website. If the Developer builds or amends a website for a Client, then the Client agrees that the Developer may include a development credit and link displayed on the Client’s web page, which may be within the code but not displayed on a web browser if requested by the Client.
        </p>
        <p>
          The Client agrees that the Developer reserves the right to include any work done for the Client in a portfolio of work.
        </p>
        <p>
          The Client agrees to abide by the terms of any third-party software or media included within any work done for the Client. Examples of this include, but are not limited to, Google-maps, Media under the Creative Commons license, RSS feeds, Open Source GPL Software etc.
        </p>
        <h3>4. Material</h3>
        <p>The Developer reserves the right to refuse to handle:</p>
        <ul>
          <li>Any media that is unlawful or inappropriate.</li>
          <li>Any media that contains a virus or hostile program.</li>
          <li>Any media that constitutes harassment, racism, violence, obscenity, harmful intent or spamming.</li>
          <li>Any media that constitutes a criminal offence, or infringes privacy or copyright.</li>
        </ul>
        <h3>5. Domain Names and Hosting</h3>
        <p>
          The Developer can, at its own discretion, but is not obliged to, offer domain name registration and hosting via a third-party service.
        </p>
        <p>
          The Client agrees that registration of a domain name does not provide an endorsement of the right to use the name. The Client is responsible for ensuring they have due title to the domain name. The Developer holds no liability and the Client hereby agrees to indemnify and hold harmless the Developer from any claim resulting from the Client’s registration of a domain name.
        </p>
        <p>
          The domain name is registered in the Client’s own name, with the address and contact details of the Developer. The Client should be aware that a domain name is registered with a third party and as such the Client shall agree to fully abide by the terms and conditions set out by the third party for such services.
        </p>
        <p>
          The Client agrees to take all legal responsibility for use of third-party domain name and hosting services and supply truthful details to the third-party services.
        </p>
        <p>
          The Client agrees that information submitted for registration of domain names is then available to the general public via the Nominet Whois system. However, Clients who are using their website for non-trading purposes may ask the third-party registrar for their contact information not to be included in the Nominet Whois system.
        </p>
        <p>
          The Client is liable to pay the Developer for any domain name registrations and the initial set-up of the hosting if included as part of the website build.
        </p>
        <p>
          Any support relating to the domain name, hosting, and email services are between the Client and the third-party service.
        </p>
        <p>
          Any other domain name and hosting services or costs not included by the Developer, including but not limited to further domain name registration fees, domain name transfer charges, yearly domain name renewals, hosting charges, yearly hosting renewals, hosting upgrades, extra disk space, bandwidth and any other related or hidden charges, are to be paid by the Client to the third-party services.
        </p>
        <p>
          The Client agrees to pay the domain name and hosting fees as soon as required by the third party. Any modifications needed to the domain name or hosting services are to be made between the Client and third-party service.
        </p>
        <p>
          The Client agrees that if at any time their contact details, including email address, change, it is their responsibility to inform the third-party registrar.
        </p>
        <p>
          The Developer will not be liable for any third-party costs or domain names that have expired.
        </p>
        <h3>6. Search Engine Optimisation (SEO)</h3>
        <p>
          The Developer offers a base level of SEO within the Developer’s development services. This includes HTML markup, meta descriptions and tagging, standard coding practices, and a robot.txt file. The Client should be aware that achieving high rankings in search engines is a complex process. The Developer makes no guarantees regarding search engine rankings.
        </p>
        <p>
          The Developer is not liable for search engine rankings or for the consequences of any changes to a website following its launch.
        </p>
        <p>
          Any further SEO work requested will be subject to a new quotation or ongoing SEO services package.
        </p>
        <h3>7. Charges and Payments</h3>
        <p>
          The Client agrees to pay the Developer the amount specified in the quotation for the development work. Payment terms are detailed in the Developer’s quotation or invoice.
        </p>
        <p>
          Payment of the invoice is due within the period specified in the quotation or invoice unless otherwise agreed in writing. The Developer reserves the right to charge interest on overdue invoices at a rate of 2% per month on the outstanding amount.
        </p>
        <p>
          The Developer reserves the right to suspend work if payment is not received within the specified period.
        </p>
        <h3>8. Termination</h3>
        <p>
          Either party may terminate the contract if the other party breaches any of its obligations under the contract and fails to remedy the breach within 14 days of receiving notice of the breach.
        </p>
        <p>
          On termination, the Client will be liable to pay for all work completed up to the date of termination.
        </p>
        <h3>9. Limitation of Liability</h3>
        <p>
          The Developer will not be liable for any indirect or consequential loss, or any loss of profit, revenue, or business arising from the Client’s use of the services.
        </p>
        <p>
          The Developer’s total liability under the contract will be limited to the amount paid by the Client for the services.
        </p>
        <h3>10. Governing Law</h3>
        <p>
          These terms and conditions shall be governed by and construed in accordance with the laws of England and Wales.
        </p>
        <p>
          Any disputes arising from the contract shall be subject to the exclusive jurisdiction of the courts of England and Wales.
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
