import React, { useState } from 'react';
import '../css/Openings.css';

const Openings = () => {
  const [formData, setFormData] = useState({
    workType: '',
    workingMode: '',
    jobTitle: '',
    location: ''
  });

  const [id, setId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [openingsData, setOpeningsData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const [showForm, setShowForm] = useState(null); // 'add', 'delete', or null
  const [showTable, setShowTable] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await fetch('https://backend.bizkranti.com/job/saveOpenings', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Network response was not ok: ${errorText}`);
      }

      setSuccess('Submission successful!');
      setFormData({
        workType: '',
        workingMode: '',
        jobTitle: '',
        location: ''
      });

    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    if (!id) {
      alert('Please enter an ID to delete.');
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch(`https://backend.bizkranti.com/job/${id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        alert('Data deleted successfully.');
        if (showTable) {
          fetchOpenings();
        }
      } else {
        const errorText = await response.text();
        throw new Error(`Failed to delete data: ${errorText}`);
      }
    } catch (error) {
      alert('An error occurred while deleting data.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOpenings = async () => {
    setIsFetching(true);
    setFetchError(null);

    try {
      const response = await fetch('https://backend.bizkranti.com/job/showOpenings');
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to fetch data: ${errorText}`);
      }
      const data = await response.json();
      setOpeningsData(data);
    } catch (error) {
      setFetchError(error.message);
    } finally {
      setIsFetching(false);
    }
  };

  const toggleTable = () => {
    if (showTable) {
      setShowTable(false);
    } else {
      fetchOpenings();
      setShowTable(true);
    }
  };

  const handleShowForm = (formType) => {
    setShowForm(formType);
    setShowTable(false);
  };

  return (
    <>
      <h1 style={{ textAlign: 'center' }}>Openings Page</h1>
      <div className="button-container">
        <button type="button" className='Opn-toggle-btn' onClick={() => handleShowForm('add')}>
          Add Openings
        </button>
        <button type="button" className='Opn-toggle-btn' onClick={() => handleShowForm('delete')}>
          Delete Openings
        </button>
        <button type="button" className='hide-btn-Delete Show' onClick={toggleTable} disabled={isFetching}>
          {isFetching ? 'Fetching Data...' : showTable ? 'Hide Data' : 'Show Data'}
        </button>
      </div>

      {showForm === 'add' && (
        <form className='Hide-Form' onSubmit={handleSubmit}>
          <h3>Add Openings</h3>
          <div className='Hide-div'>
            <label className='Hide-label'>jobTitle:</label>
            <input
              type="text"
              name="jobTitle"
              value={formData.jobTitle}
              onChange={handleChange}
              className='Input-hide'
              required
            />
          </div>
          <div className='Hide-div'>
            <label className='Hide-label'>location:</label>
            <input
              type="text"
              name="location"
              value={formData.location}
              onChange={handleChange}
              className='Input-hide'
              required
            />
          </div>
          <div className='Hide-div'>
            <label className='Hide-label'>Job Type:</label>
            <select
              name="workType"
              className='select-hide'
              value={formData.workType}
              onChange={handleChange}
              required
            >
              <option value="" disabled>Select Job Type</option>
              <option value="full-time">Full Time</option>
              <option value="part-time">Part Time</option>
            </select>
          </div>
          <div className='Hide-div'>
            <label className='Hide-label'>Work Type:</label>
            <select
              name="workingMode"
              className='select-hide'
              value={formData.workingMode}
              onChange={handleChange}
              required
            >
              <option value="" disabled>Select Work Type</option>
              <option value="remote">Remote</option>
              <option value="wfo">WFO</option>
              <option value="wfh">WFH</option>
            </select>
          </div>
          <div className='Hide-div'>
            <button type="submit" className='hide-btn' disabled={isLoading}>
              {isLoading ? 'Submitting...' : 'Submit'}
            </button>
            {error && <p className='error-message'>An error occurred: {error}</p>}
            {success && <p className='success-message'>{success}</p>}
          </div>
        </form>
      )}

      {showForm === 'delete' && (
        <>
          <h3 style={{ textAlign: 'center', marginTop: '20px' }}>Delete Data</h3>
          <div className='Main-Div-Delete'>
            <div className='Hide-div-Delete'>
              <label className='Hide-label'>ID:</label>
              <input
                type="text"
                name="id"
                className='Input-hide-Deleted'
                value={id}
                onChange={(e) => setId(e.target.value)}
                placeholder="Enter ID to delete"
                required
              />
            </div>
            <button
              type="button"
              className='hide-btn-Delete'
              onClick={handleDelete}
              disabled={isLoading}
            >
              {isLoading ? 'Deleting...' : 'Delete'}
            </button>
          </div>
        </>
      )}

      {fetchError && <p className='error-message' style={{ textAlign: 'center' }}>Error: {fetchError}</p>}
      {showTable && openingsData.length > 0 && (
        <div className='table-container'>
          <table className='openings-table'>
            <thead>
              <tr>
                <th>ID</th>
                <th>location</th>
                <th>jobTitle</th>
                <th>Work Time</th>
                <th>Working Mode</th>
              </tr>
            </thead>
            <tbody>
              {openingsData.map((data) => (
                <tr key={data.id}>
                  <td>{data.id}</td>
                  <td>{data.location}</td>
                  <td>{data.jobTitle}</td>
                  <td>{data.workType}</td>
                  <td>{data.workingMode}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}

export default Openings;
